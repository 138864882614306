import React, { useCallback, useContext, useState } from "react";
import { Toast, ToastProps } from "react-bootstrap";
import ToastContainer from 'react-bootstrap/ToastContainer';

export function MyToast(props: ToastProps & { header?: string}) {
	const [show, setShow] = useState(true);
	return <Toast bg="dark" show={show} autohide delay={5000} onClose={() => {
		setShow(false);
	}} {...props}>
		<Toast.Header closeButton>
			<strong className="me-auto">{props.header ?? "Alert!"}</strong>
		</Toast.Header>
		<Toast.Body>{props.children}</Toast.Body>
	</Toast>
}


interface IToastContext {
	addToast: (content:string, header?:string) => void;
	removeToast: (id:number) => void;
}

const ToastContext = React.createContext<IToastContext>(null);

let id = 1;

interface IToast {
	id: number;
	content: string;
	header: string;
}
const ToastProvider = ({ children }) => {
  const [toasts, setToasts] = useState<IToast[]>([]);

  const addToast = useCallback(
    (content:string, header?:string) => {
      setToasts(toasts => [
        ...toasts,
        {
          id: id++,
          content,
		  header
        }
      ]);
    },
    [setToasts]
  );

  const removeToast = useCallback(
    (id:number) => {
      setToasts(toasts => toasts.filter(t => t.id !== id));
    },
    [setToasts]
  );

  return (
    <ToastContext.Provider
      value={{
        addToast,
        removeToast
      }}
	  >
		{children}
		<ToastContainer style={{paddingTop: 80+70, marginTop: -70, paddingRight: "1em", zIndex: "9999 !important"}} position="top-end" className="position-fixed">
			{toasts.map(toast => (
				<Toast key={toast.id} onClose={() => removeToast(toast.id)} bg="dark" autohide delay={5000}>
					<Toast.Header closeButton>
						<strong className="me-auto">{toast.header ?? "Alert!"}</strong>
					</Toast.Header>
					<Toast.Body>
						{toast.content}
					</Toast.Body>
				</Toast>
			))}
		</ToastContainer>
    </ToastContext.Provider>
  );
};

const useToast = () => {
  const toastHelpers = useContext(ToastContext);

  return toastHelpers;
};

export { ToastContext, useToast };
export default ToastProvider;
