import { MyTable } from "Components/MyTable";
import IStyle from "Interfaces/IStyle";
import { ITableRow } from "Interfaces/ITableRow";
import { useToast } from "Layouts/Toast";
import MersenneTwister from "mersenne-twister";
import { SchaleStudent } from "Models/Student";
import StudentMap from "Models/StudentMap";
import React, { useState } from "react";
import { Alert, Button, Col, Row } from "react-bootstrap";
// CLick / tap and highlight boxes. again to toggle down/right
const EVEN = "02468";
const now = new Date();
const seedNow = now.getUTCFullYear() * 10000 + now.getUTCMonth() * 100 + now.getUTCDate();
const CELLSIZE = "3vw"
const BOARD_SIZE = 21;
const style:IStyle = {
	noneCell: {
		opacity: 0,
		width: CELLSIZE,
		height: CELLSIZE,
		// border: "1px solid",
		// borderColor: "pink",
	},
	cell: {
		opacity: 1,
		width: CELLSIZE,
		height: CELLSIZE,
		border: "1px solid",
		borderColor: "pink",
		color: "black",
		verticalAlign: "top"
	}
}

type Direction = "horizontal" | "vertical";
interface IWord {
	word: string;
	student: SchaleStudent;
	direction: Direction;
	x: number;
	y: number;
	placed?: boolean;
	taken: number[]
}

class Word implements IWord, ITableRow {
	word: string;
	student: SchaleStudent;
	direction: Direction;
	x: number;
	y: number;
	placed?: boolean;
	taken: number[];
	constructor(word: IWord) {
		this.word = word.word;
		this.student = word.student;
		this.direction = word.direction;
		this.x = word.x;
		this.y = word.y;
		this.placed = word.placed;
		this.taken = word.taken;
	}
	getHeaders() {
		return ["#", 
			// "Direction", 
			"Role", "DMG", "Month"];
	}
	toArray() {
		return [(this.x * BOARD_SIZE + this.y).toLocaleString(), 
			// this.direction, 
			this.student.TacticRole, this.student.BulletType, this.student.BirthMonth];
	}
	toKeyValues() {
		return {
			"#": (this.x * BOARD_SIZE + this.y).toLocaleString(),
			// "Direction": this.direction,
			"Role": this.student.TacticRole,
			"DMG": this.student.BulletType,
			"Month": this.student.BirthMonth
		}
	}
}

export default function Crossword() {
	const [board, setBoard] = useState([]);
	const [answers, setAnswers] = useState<Word[]>([]);
	const [userDirection, setUserDirection] = useState<Direction>("horizontal");
	const [focusedCell, setFocusedCell] = useState<number>(0);

	const toast = useToast();
	const mt = new MersenneTwister(seedNow);
	class ComparatorString {
		current: number = 0;
		inputString: string;
		constructor(inputString: string) {
			this.inputString = inputString;
			// console.log(`Creating Comparator String:\n${this.inputString}`);
		}

		// 0 = Tie, 1 = Left, 2 = Right
		compareNext() {
			const result = this.inputString.charAt(this.current);
			this.current = (this.current + 1) % this.inputString.length;
			if (EVEN.indexOf(result) >= 0)
				return -1;
			return 1;
		}
	}
	function generateCrossword(students: SchaleStudent[], gridSize: number): string[][] {
		const grid: string[][] = Array.from({ length: gridSize }, () => Array(gridSize).fill('.'));

		const wordObjects: Word[] = students.map((student, index) => new Word({
			word: student.Name.toUpperCase(),
			student: student,
			direction: index % 2 === 0 ? 'horizontal' : 'vertical',
			x: 0,
			y: 0,
			placed: false,
			taken: []
		}));

		wordObjects[0].x = Math.max(0, Math.min(gridSize - wordObjects[0].word.length, Math.floor((gridSize + wordObjects[0].word.length) / 2)));
		wordObjects[0].y = Math.floor(gridSize / 2);

		const puzzle: Word[] = [wordObjects[0]];
		placeWord(wordObjects[0]);
		// console.log(wordObjects[0].word);
		for (let i = 1; i < wordObjects.length; i++) {
			if (puzzle.length >= BOARD_SIZE) break;
			const word = wordObjects[i];
			// console.log(word.word);
			// console.log(`- Trying to put ${word.word}`)
			for (const item of puzzle) {
				const targetWord = item.word;
				const insertWord = word.word;
				// console.log(`Trying to put ${insertWord} comparing with ${targetWord}`);
				if (item.direction === 'horizontal') {
					word.direction = 'vertical';
				} else {
					word.direction = 'horizontal';
				}
				for (let j = 0; j < insertWord.length; j++) {
					const refChar = insertWord.charAt(j);
					// console.log(`Trying to find ${refChar} in ${targetWord}`);
					for (let pos = targetWord.indexOf(refChar, 0); pos >= 0; pos = targetWord.indexOf(refChar, pos + 1)) {
						// console.log(`Trying to find ${refChar} in ${targetWord} (${pos})`);
						if (word.direction === 'horizontal') {
							word.x = item.x - j;
							word.y = item.y + pos;
						} else {
							word.x = item.x + pos;
							word.y = item.y - j;
						}
						if (item.taken.indexOf(pos) < 0 && placeWord(word)) {
							word.placed = true;
							item.taken.push(pos);
							puzzle.push(word);
							break;
						}
					}
					if (word.placed)
						break;
				}
				if (word.placed)
					break;
			}
		}

		setBoard(grid);
		setAnswers(puzzle.sort((a,b)=>(a.x*BOARD_SIZE + a.y) - (b.x*BOARD_SIZE + b.y)).sort((a,b)=>a.direction.localeCompare(b.direction)));
		document.querySelectorAll(".crossword td.cell input").forEach((cell:HTMLInputElement) => {
			cell.value = '';
		});


		function get(x: number, y: number) {
			if (!grid[x]) return '.'
			if (!grid[x][y]) return '.'
			return grid[x][y];
		}


		function isValidGrid(wordObj: Word): boolean {
			// Check for overlapping words
			const { word, direction, x, y } = wordObj;
			if (x < 0 || y < 0)
				return false;
			const wordLength = word.length;
			if (direction === 'horizontal' && x + word.length > grid.length) return false;
			if (direction === 'vertical' && y + word.length > grid.length) return false;
			for (let i = 0; i < wordLength; i++) {
				const gridX = direction === 'horizontal' ? x + i : x;
				const gridY = direction === 'vertical' ? y + i : y;
				if (grid[gridX][gridY] !== '.' && grid[gridX][gridY] !== word.charAt(i)) return false;

				// if (grid[gridX][gridY-1] && grid[gridX][gridY-1] !== '.') return false;

				if (direction === 'horizontal') {
					if (get(gridX, gridY) === '.' && get(gridX, gridY-1) !== '.') return false;
					if (get(gridX, gridY) === '.' && get(gridX, gridY+1) !== '.') return false;

					// if (get(gridX, gridY - 1) !== '.' && get(gridX - 1, gridY - 1) !== '.') return false;
					// if (get(gridX, gridY + 1) !== '.' && get(gridX - 1, gridY + 1) !== '.') return false;
					// if (get(gridX, gridY - 1) !== '.' && get(gridX + 1, gridY - 1) !== '.') return false;
					// if (get(gridX, gridY + 1) !== '.' && get(gridX + 1, gridY + 1) !== '.') return false;
					
					// if (grid[gridX][gridY-1] && grid[gridX][gridY-1] !== '.') return false;
					// if (grid[gridX][gridY+1] && grid[gridX][gridY+1] !== '.') return false;
				} else {
					if (get(gridX, gridY) === '.' && get(gridX-1, gridY) !== '.') return false;
					if (get(gridX, gridY) === '.' && get(gridX+1, gridY) !== '.') return false;

					// if (get(gridX - 1, gridY) !== '.' && get(gridX - 1, gridY - 1) !== '.') return false;
					// if (get(gridX + 1, gridY) !== '.' && get(gridX + 1, gridY - 1) !== '.') return false;
					// if (get(gridX - 1, gridY) !== '.' && get(gridX - 1, gridY + 1) !== '.') return false;
					// if (get(gridX + 1, gridY) !== '.' && get(gridX + 1, gridY + 1) !== '.') return false;
					
					// if (grid[gridX-1] && grid[gridX-1][gridY] !== '.') return false;
					// if (grid[gridX+1] && grid[gridX+1][gridY] !== '.') return false;
				}
			}

			if (direction === 'horizontal') {
				if (get(x + word.length, y) !== '.') return false;
				if (get(x - 1, y) !== '.') return false;
			} else {
				if (get(x, y - 1) !== '.') return false;
				if (get(x, y + word.length) !== '.') return false;
			}

			return true;
		}

		// Function to place a word on the grid
		function placeWord(wordObj: Word): boolean {
			const { word, direction, x, y } = wordObj;
			const wordLength = word.length;

			// Check if the word fits within the grid boundaries
			if (direction === 'horizontal' && y + wordLength > gridSize) return false;
			if (direction === 'vertical' && x + wordLength > gridSize) return false;

			if (!isValidGrid(wordObj))
				return false;

			// Place the word on the grid
			for (let i = 0; i < wordLength; i++) {
				const gridX = direction === 'horizontal' ? x + i : x;
				const gridY = direction === 'vertical' ? y + i : y;
				grid[gridX][gridY] = word.charAt(i);
			}

			// grid.forEach(row => {
			// 	console.log(row.join(' '));
			// });

			return true;
		}

		return grid;
	}
	
	function generatePool(compareString?: string) {
		const comparator = new ComparatorString(compareString ?? mt.random_int().toString());
		function mergeSort(array: SchaleStudent[]): SchaleStudent[] {
			if (array.length <= 1) {
				return array;
			}
			const middle = Math.floor(array.length / 2);
			const leftHalf = array.slice(0, middle);
			const rightHalf = array.slice(middle);
			return merge(mergeSort(leftHalf), mergeSort(rightHalf));
		}

		function merge(left: SchaleStudent[], right: SchaleStudent[]): SchaleStudent[] {
			let result: SchaleStudent[] = [];
			let leftIndex = 0;
			let rightIndex = 0;

			while (leftIndex < left.length &&
				rightIndex < right.length) {
				const compare = comparator.compareNext();
				// console.log(`Compare Result: ${compare}`);
				if (compare > 0) {
					result.push(left[leftIndex]);
					leftIndex++;
				} else {
					result.push(right[rightIndex]);
					rightIndex++;
				}
			}

			return result.concat(left.slice(leftIndex)).
				concat(right.slice(rightIndex));
		}

		return mergeSort([...new Set(StudentMap.schaleStudent.filter(s => s.Name.indexOf(' ') < 0))]);
	}

	function isCorrect() {
		for (const answer of answers) {
			const {direction, x, y, word} = answer;
			for (let i=0; i<word.length; i++) {
				let id=0;
				if (direction === "vertical") {
					id = x*BOARD_SIZE + (y+i);
				} else {
					id = (x+i)*BOARD_SIZE + y;
				}
				const cell = document.getElementById(`cell_${id}`) as HTMLInputElement;
				if (cell.value.charAt(0) !== word.charAt(i)) return false;
			}
		}

		return true;
	}

	const toggleDirection = () => {
		if (userDirection === "horizontal") {
			setUserDirection("vertical");
		} else {
			setUserDirection("horizontal");
		}
	}

	return (<>
	<Alert variant="warning">This is still in the early stages of development. This should refresh whenever SchaleDB adds a new student or the day changes (UTC). Only Names with no Spaces are included here so no Alts/Terror/Collab</Alert>
		
		<Row>
			<Col xs={12} lg={4}>
			<Row>
			<Col>
				<h4 className="text-pink">Generate</h4>
				<Button variant="violet" onClick={() => {
					const words = generatePool();
					generateCrossword(words, BOARD_SIZE);
				}}>Today's Crossword</Button>
				<Button variant="violet" onClick={() => {
					const words = generatePool(Date.now().toString());
					generateCrossword(words, BOARD_SIZE);
				}}>Random Crossword</Button>
			</Col>
		</Row>
		<Row>
			<Col>
				<h4 className="text-pink">You are going: {userDirection === "horizontal" ? "RIGHT" : "DOWN"}</h4>
				<Button variant="secondary" onClick={() => {
					toggleDirection();
					document.getElementById(`cell_${focusedCell}`)?.focus();
				}}>Go {userDirection === "vertical" ? "RIGHT" : "DOWN"}</Button>
			</Col>
		</Row>
		<Row>
			<Col>
				<h4 className="text-pink">Done?</h4>
				<Button variant="danger" onClick={() => {
					if (!isCorrect()) {
						toast.addToast("Incorrect");
					} else {
						toast.addToast("Correct");
					}
				}}>Check Answers</Button>
			</Col>
		</Row>
		
				<Row>
					<Col xs={12} md={6} lg={12}>
						<h4 className="text-pink">Right</h4>
						<MyTable variant="violet" data={answers.filter(w=>w.direction === "vertical")} 
							onCellClick={(e)=>{
								const id = parseInt(e.currentTarget.parentElement.firstChild.textContent);
								const cell = document.getElementById(`cell_${id}`) as HTMLInputElement;
								setUserDirection("horizontal")
								cell.focus();
							}}
						/>
				</Col>
				<Col>
					<h4 className="text-pink">Down</h4>
					<MyTable variant="violet" data={answers.filter(w=>w.direction === "horizontal")} 
						onCellClick={(e)=>{
							const id = parseInt(e.currentTarget.parentElement.firstChild.textContent);
							const cell = document.getElementById(`cell_${id}`) as HTMLInputElement;
							setUserDirection("vertical")
							cell.focus();
						}}
						/>
				</Col>
				</Row>
			</Col>
		{/* </Row>
		<Row> */}
			<Col xs={"auto"}>
				<table className="crossword">
					<tbody>
						{board.map((row, i) => {
							if (row.filter(r => r !== '.').length === 0) return null;
							return (<tr>
								{row.map( (cell, j) => {
									if (cell === '.') 
										return <td style={style.noneCell}></td>
									const id = i*BOARD_SIZE + j;
									const key = `cell_${id}`;
									return <td className="cell" key={"_"+key} style={style.cell} onFocus={()=>{
										document.getElementById(key).focus();
									}} onClick={()=>{
										document.getElementById(key).focus();
									}}>{id}<input onFocus={(e)=>{
										setFocusedCell(id);
										e.target.select();
									}} onKeyUp={(e)=>{
										switch (e.key) {
											case "ArrowUp":
												setUserDirection("vertical");
												document.getElementById(`cell_${id-BOARD_SIZE}`)?.focus();
												break;
											case "ArrowLeft":
												setUserDirection("horizontal");
												document.getElementById(`cell_${id-1}`)?.focus();
												break;
											case "Enter":
											case "ArrowDown":
												setUserDirection("vertical");
												document.getElementById(`cell_${id+BOARD_SIZE}`)?.focus();
												break;
											case "ArrowRight":
												setUserDirection("horizontal");
												document.getElementById(`cell_${id+1}`)?.focus();
												break;
											case " ":
												// e.consume();
												toggleDirection();
											default:
												break;
										}
									}}
									
									onChange={(e)=>{
										// console.log(e);
										let nextVal = e.target.value.toUpperCase();
										if (e.target.value.length > 1)
											nextVal = e.target.value.charAt(1).toUpperCase();
										if (nextVal === " ") {
											e.target.value = e.target.value.trim();
											return;
										}
										e.target.value = nextVal;
										if (userDirection === "horizontal") {
											document.getElementById(`cell_${id+1}`)?.focus();
										} else {
											document.getElementById(`cell_${id+BOARD_SIZE}`)?.focus();
										}
									}} id={key} style={{width: "100%", color: "black", textAlign: "center", border: "none", background: "rgba(0,0,0,0)"}} type="text">
										</input></td>
								})}
							</tr>)
						})}
					</tbody>
				</table>
			</Col>
		</Row>
	</>);
}