import IconButton from "Components/IconButton";
import IStyle from "Interfaces/IStyle";
import { IStudentInfoProps } from "Interfaces/Props/IStudentInfoProps";
import { useToast } from "Layouts/Toast";
import StudentMap from "Models/StudentMap";
import React from "react";
import { Card, Col, Row } from 'react-bootstrap';

const styles:IStyle = {
	buttonLink: {
		margin: 5,
	}
}

export function StudentDetails(props: IStudentInfoProps) {
	const toast = useToast();

	return (<>
		<Row>
			<Col>
				<Card>
					<Card.Body>
						<Card.Img variant="top" src={new StudentMap().getIcon(props.target)} />
					</Card.Body>
					<Card.Footer>{new StudentMap().getProperName(props.target)}</Card.Footer>
				</Card>
			</Col>
		</Row>
		<Row style={{ marginTop: 10 }}>
			<Col style={styles.buttonLink}>
				<IconButton 
					// image="/logo512.png"
					image="https://ssl.gstatic.com/docs/spreadsheets/favicon3.ico"
					label="Sheet"
					href="https://docs.google.com/spreadsheets/d/1BO-Uh_CsAOIetPjrNyFy9-7r1a4RAtVwK135bBC3hzM" 
				/>
			</Col>
			<Col style={styles.buttonLink}>
				<IconButton 
					image="https://www.google.com/s2/favicons?sz=128&domain=schaledb.com"
					label="SCHALE DB"
					href={new StudentMap().getSchale(props.target)} 
				/>
			</Col>
			<Col style={styles.buttonLink}>
				<IconButton 
					image="/logo512.png"
					label="Share"
					onClick={async () => {
						const url = `https://${document.location.hostname}/StudentInsights/${props.target.replaceAll(' ', '_')}`;
						await navigator.clipboard.writeText(url);
						toast.addToast(url, "Added URL to clipboard!");
					}}
				/>
			</Col>
		</Row>
	</>);
}
