import PVPHistoryFilters, { PVPHistoryPlayerFilters } from "Components/Filters/PVPHistoryFilters";
import PVPBattleHistoryItemRenderer from "Components/ModelRenderers/PVPBattleHistoryItemRenderer";
import MyLoadingSpinner from "Components/MyLoadingSpinner";
import MyPagination from "Components/MyPagination";
import { BUCKET_HOST } from "Constants/Common";
import { IPVPHistoryFilter, IPVPHistoryPlayerFilterItem } from "Interfaces/IPVPHistoryFilter";
import IStyle from "Interfaces/IStyle";
import BattleHistoryItem from "Models/PVP/BattleHistoryItem";
import Player from "Models/PVP/Player";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Alert, Button, Col, Form, Row } from "react-bootstrap";
import { useParams } from "react-router-dom";

const style:IStyle = {
	button: {marginRight: 5},
}
interface IProps {
	key?: string;
}
const BA_JP_RELEASE = "2021-02-04"
// export let RAID_DATA_SCORE_CALCULATORS:RaidScore[];
export default function PVPHistory() {
	const { key } = useParams();
	const tomorrow = (moment(new Date())).add(1, "days").format("YYYY-MM-DD");
	const [data, setData] = useState<BattleHistoryItem[]>([]);
	const [isFilterInverted, setisFilterInverted] = useState(false);

	const [filters, setFilters] = useState<IPVPHistoryFilter>({
		GEN: {
			from: BA_JP_RELEASE,
			to: tomorrow,
			winner: "ANY"
		},
		ATK: {
			includeStudents: [],
			excludeStudents: [],
		},
		DEF: {
			includeStudents: [],
			excludeStudents: [],
		},
		ANY: {
			includeStudents: [],
			excludeStudents: [],
		},
	});
	const isPublic = typeof(key) === "undefined" || key === "" || key === "history" || data?.length === 0
	const [isReportVisible, setIsReportVisible] = useState(false);
	const [isLoading, setIsLoading] = useState(true);
	const [compact, setIsCompact] = useState(true);
	const [displayedData, setDisplayedData] = useState<BattleHistoryItem[]>([]);
	// const initialDisplayedData = useMemo( () => filteredData(), [filters, data] );
	const root = BUCKET_HOST+`/files/data/pvp`;

	useEffect(() => {
		let ignore = false;
		async function loadData() {
			setIsLoading(true);
			if (key === "master") return;
			const getHistory = new BattleHistoryItem().fromTsvUrl(`${root}/${key ?? "history"}.tsv`)
			const fromTsv = await getHistory;
			if (!ignore) {
				setData(fromTsv);
				setDisplayedData(filteredData(fromTsv))
				if (fromTsv && key?.length)
					localStorage.setItem("midokuni.pvp.private.key", key);
			}
			setIsLoading(false);
		}
		loadData()

		return () => {
			ignore = true;
		}
	}, []);
	
	function hasFilters() {
		function hasPlayerFilter(filters:IPVPHistoryPlayerFilterItem) {
			return filters && (
				(filters.minRank && filters.minRank > 0) || 
				(filters.maxRank && filters.maxRank > 0) || 
				filters.includeStudents?.length || 
				filters.includeStudents?.length || 
				filters.includeStudents?.length || 
				filters.excludeStudents?.length ||
				filters.excludeStudents?.length ||
				filters.excludeStudents?.length ||
				filters.sensei?.length
			)
		}

		function hasGeneralFilter() {
			return filters.GEN && (
				filters.GEN.from > BA_JP_RELEASE ||
				filters.GEN.to < tomorrow ||
				filters.GEN.winner !== "ANY"
			)
		}

		return hasPlayerFilter(filters.ANY) || 
			hasPlayerFilter(filters.ATK) || 
			hasPlayerFilter(filters.DEF) || 
			hasGeneralFilter();
	}

	function filteredData(toFilter?: BattleHistoryItem[]) {
		if(hasFilters()) {
			setIsLoading(true);
			const filtered:BattleHistoryItem[] = [];
			for (const row of toFilter ?? data ?? []) {
				if (applyFilter(row) !== isFilterInverted)
					filtered.push(row);
			}
			
			setIsLoading(false);
			return filtered;
		}

		return toFilter ?? data ?? [];
	}

	function PrivateWarning() {
		if (isPublic) return (
			<Alert variant="warning" dismissible>
				For the protection of the data providers, I will not be displaying data for the current Global Season + Map.
			</Alert>
			);
		return (
			<Alert variant="danger">
				<h1>READ ME!</h1>
				Please keep this data here private. This could lead to your downfall if you share because it will show your entire trends on def -- or even your actual def right now.
				<br></br>
				There is no Authentication on this Website so anyone can see anything if they know the URL.
				<br></br>
				I'm at least supplying different URLs to each person to help figure out if anyone has provided theirs to another person.
			</Alert>
			)
	}

	function applyFilter(row: BattleHistoryItem) {
		function applyFilter(filters:IPVPHistoryPlayerFilterItem, players: Player[]) {
			for (const player of players) {
				if (filters.maxRank && player.rankBeforeBattle > filters.maxRank)
					return false;
				if (filters.minRank && player.rankBeforeBattle < filters.minRank)
					return false;
				if (filters.includeStudents && filters.includeStudents.length > 0) {
					for(const student of filters.includeStudents) {
						if (!player.findStudent(student))
							return false;
					}
				}
				if (filters.excludeStudents && filters.excludeStudents.length > 0) {
					for(const student of filters.excludeStudents) {
						if (player.findStudent(student))
							return false;
					}
				}

				if (filters.sensei?.length) {
					const isAtk = player.name.indexOf(filters.sensei) >= 0,
						isDef = player.name.indexOf(filters.sensei) >= 0;
					if (!isAtk && !isDef) 
						return false;
				}
			}

			return true;
		}

		function applyGeneralFilter() {
			const battleDate = row.getDate();
			if (battleDate < filters.GEN.from) return false;
			if (battleDate > filters.GEN.to) return false;
			const checkAtk = filters.GEN.winner === "ATK";
			if (checkAtk && !row.didAttackWin) return false;
			const checkDef = filters.GEN.winner === "DEF";
			if (checkDef && row.didAttackWin) return false;

			return true;
		}

		if (!applyGeneralFilter())
			return false;

		if (!applyFilter(filters.ATK, [row.attacker])) {
			return false;
		}

		if (!applyFilter(filters.DEF, [row.defender])) {
			return false;
		}
		if (!applyFilter(filters.ANY, [row.attacker]) && !applyFilter(filters.ANY, [row.defender])) {
			return false;
		}


		return true;
	}

	// TODO: thread worker this so it isn't blocking
	const generateAnalytics = () => {
		// const totalPlayers = displayedData.length;
		// RaidReportItem.totalPlayers = totalPlayers;
		// if (filters.fullTeamsOnly) {
		// 	let attendanceCount = {},
		// 	assistantCount = {},
		// 	totalAttendancePerSensei = {},
		// 	teamCount = {},
		// 	teams = {},
		// 	totalTeams = 0,
		// 	students:{[id:string|number]: Student} = {},
		// 	studentScores:{[id:string|number]: number[]} = {},
		// 	studentTeamsUsed:{[id:string|number]: number[]} = {},
		// 	teamTeamsUsed:{[id:string|number]: number[]} = {},
		// 	teamScores:{[id:string|number]: number[]} = {}
		// 	;
		// 	for (const player of displayedData) {
		// 		let hasAssist = false, 
		// 			hasNoEmpty = true;
		// 		for (const team of player.teams) {
		// 			const teamKey = team.asKey();
		// 			teams[teamKey] = team;
		// 			teamCount[teamKey] = (teamCount[teamKey] ?? 0) + 1;
		// 			teamTeamsUsed[teamKey] = teamTeamsUsed[teamKey] ?? [];
		// 			teamTeamsUsed[teamKey].push(player.teams.length);
		// 			teamScores[teamKey] = teamScores[teamKey] ?? [];
		// 			teamScores[teamKey].push(player.score);
		// 			totalTeams++;
		// 			for (const student of [
		// 				...team.strikers,
		// 				...team.specials
		// 			]) {
		// 				const studentId = student.getId();
		// 				students[studentId] = student;
		// 				if (student.isAssist) {
		// 					hasAssist = true;
		// 					assistantCount[student.id] = assistantCount[student.id] ?? {};
		// 					assistantCount[student.id].total = (assistantCount[student.id].total ?? 0) + 1;
		// 					assistantCount[student.id][student.getTier()] = (assistantCount[student.id][student.getTier()] ?? 0) + 1;
		// 					studentTeamsUsed[studentId] = studentTeamsUsed[studentId] ?? [];
		// 					studentTeamsUsed[studentId].push(player.teams.length);
		// 					studentScores[studentId] = studentScores[studentId] ?? [];
		// 					studentScores[studentId].push(player.score);
		// 				} else if (!student.isEmpty()) {
		// 					attendanceCount[student.id] = attendanceCount[student.id] ?? {};
		// 					attendanceCount[student.id].total = (attendanceCount[student.id].total ?? 0) + 1;
		// 					attendanceCount[student.id][student.getTier()] = (attendanceCount[student.id][student.getTier()] ?? 0) + 1;
		// 					studentTeamsUsed[studentId] = studentTeamsUsed[studentId] ?? [];
		// 					studentTeamsUsed[studentId].push(player.teams.length);
		// 					studentScores[studentId] = studentScores[studentId] ?? [];
		// 					studentScores[studentId].push(player.score);
		// 				} else if (hasNoEmpty) {
		// 					attendanceCount["EMPTY"] = attendanceCount["EMPTY"] ?? {};
		// 					attendanceCount["EMPTY"].total = (attendanceCount["EMPTY"].total ?? 0) + 1;
		// 					studentTeamsUsed[studentId] = studentTeamsUsed[studentId] ?? [];
		// 					studentTeamsUsed[studentId].push(player.teams.length);
		// 					studentScores[studentId] = studentScores[studentId] ?? [];
		// 					studentScores[studentId].push(player.score);
		// 					hasNoEmpty = false;
		// 				}
		// 			}
		// 		}
		// 		if (!hasAssist) {
		// 			assistantCount["EMPTY"] = (assistantCount["EMPTY"] ?? 0) + 1;
		// 		}
		// 	}
		// 	const studentList = Object.values(students);
		// 	const teamKeyList = Object.keys(teams);
		// 	const participationReport:RaidStudentReportItem[] = [];
		// 	const teamsReport:RaidTeamReportItem[] = [];
		// 	RaidTeamReportItem.totalTeams = totalTeams;
		// 	const filterWithoutStudent = (id) => {
		// 		const teams:number[] = [];
		// 		const score:number[] = [];
		// 		for (const data of displayedData) {
		// 			if (!data.findStudentById(id)) {
		// 				score.push(data.score);
		// 				teams.push(data.teams.length);
		// 			}
		// 		}

		// 		return [score, teams];
		// 	}
		// 	for (const student of studentList) {
		// 		const id = student.getId();
		// 		const [scoreWithout, teamsWithout] = filterWithoutStudent(id);
		// 		participationReport.push(new RaidStudentReportItem(
		// 			student,
		// 			attendanceCount[id] ?? {total:0},
		// 			assistantCount[id] ?? {total:0},
		// 		)
		// 		.calculateScoreData(studentScores[id], scoreWithout)
		// 		.calculateTeamData(studentTeamsUsed[id], teamsWithout)
		// 		);
		// 	}

		// 	for (const key of teamKeyList) {
		// 		teamsReport.push(new RaidTeamReportItem(
		// 			key,
		// 			teams[key],
		// 			teamCount[key]
		// 		)
		// 		.calculateScoreData(teamScores[key], [])
		// 		.calculateTeamData(teamTeamsUsed[key], [])
		// 		)
		// 	}
			

		// 	participationReport.sort((a,b) => b.attendance.total - a.attendance.total);
		// 	teamsReport.sort((a,b) => b.count - a.count);
		// 	setDisplayedParticipationReport(participationReport);
		// 	setStudentDataReport([...participationReport]
		// 		.filter((a)=>a.score.meanWithout>0)
		// 		.sort((a,b) => (b.score.meanWith - b.score.meanWithout) - ((a.score.meanWith - a.score.meanWithout)))
		// 	)
		// 	setTeamReport(teamsReport);
		// } 
		// processRaidReport();
		// setIsReportVisible(true);
	}
	
// TODO: Move Modal to a different view instead but under same component
	return (
		<>
		<PrivateWarning />
		<Row>
			<Col>
				<Row>
					<Col>
						<h3 className="text-pink">General Filters</h3>
						<PVPHistoryFilters isPublic={isPublic} filters={filters.GEN} setFilters={(newFilter) => {
							setFilters({
								...filters,
								GEN: newFilter
							})
						}}/>
					</Col>
				</Row>
				<Row>
					<Col>
						<h3 className="text-pink">ANY</h3>
						<PVPHistoryPlayerFilters isPublic={isPublic} filters={filters.ANY} setFilters={(newFilter) => {
							setFilters({
								...filters,
								ANY: newFilter
							})
						}} />
					</Col>
					<Col>
						<h3 className="text-pink">ATK</h3>
						<PVPHistoryPlayerFilters isPublic={isPublic} filters={filters.ATK} setFilters={(newFilter) => {
							setFilters({
								...filters,
								ATK: newFilter
							})
						}} />
					</Col>
					<Col>
						<h3 className="text-pink">DEF</h3>
						<PVPHistoryPlayerFilters isPublic={isPublic} filters={filters.DEF} setFilters={(newFilter) => {
							setFilters({
								...filters,
								DEF: newFilter
							})
						}} />
					</Col>
				</Row>
				<Row>
					<Col md={2}>
					</Col>
					<Col>
						<div key="invert-filter" className="mb-3">
							<Form.Check 
							checked={isFilterInverted}
							type="switch"
							id="invert-filter"
							label="Invert Filter Results?"
							onChange={(a)=>{
								setisFilterInverted(a.target.checked);
							}}
							/>
						</div>
					</Col>
				</Row>
				<Row>
					<Col md={2}>
					</Col>
					<Col>
						<div key="student-compact" className="mb-3">
							<Form.Check 
							checked={compact}
							type="switch"
							id="student-compact"
							label="Compact Mode"
							onChange={(a)=>{
								setIsCompact(a.target.checked);
							}}
							/>
						</div>
					</Col>
				</Row>
				<Row>
					<Col>
						<Button variant="secondary" style={style.button} onClick={()=>{
							setDisplayedData(filteredData());
						}}>
							Apply Filters
						</Button>
						<Button variant="secondary" style={style.button} onClick={generateAnalytics} 
						disabled={true || isLoading || (displayedData?.length == 0)}>
							Generate Analytics
						</Button>
					</Col>
				</Row>
			</Col>
		</Row>
		<Row hidden={isLoading || isReportVisible}>
			<Col>
				<MyPagination params={{compact: compact, isPublic: isPublic}} RowAs={PVPBattleHistoryItemRenderer} data={displayedData ?? []} itemsPerPage={20}></MyPagination>
			</Col>
		</Row>
		<Row  style={{alignContent: "center", marginLeft: "1em", marginTop: "1em"}}>
			<Col>
				<MyLoadingSpinner isLoading={isLoading} size={100}/>
			</Col>
		</Row>
		</>
	)
}