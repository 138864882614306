import 'App.css';
import MyBrandFooter from 'Components/MyBrandFooter';
import BaseLayout from 'Layouts/BaseLayout';
import { Message } from 'Layouts/Message';
import ToastProvider from 'Layouts/Toast';
import { CategoryScale, Chart as ChartJS, Filler, Legend, LineElement, LinearScale, PointElement, Title, Tooltip } from "chart.js";
import * as React from 'react';
import { Col, Row } from 'react-bootstrap';
import Container from 'react-bootstrap/Container';
import { MidokuniNavBar } from './MidokuniNavBar';

export class Default extends BaseLayout {
	_handleKeyDown = (event: { keyCode: any; }) => {
		switch( event.keyCode ) {
			case 191: // "/" Key
				const search:HTMLInputElement = document.querySelector("input.search");
				search?.focus();
				search?.select();
				break;
			default: 
				// console.log(event.keyCode)
				break;
		}
	}
	componentDidMount() {
		super.componentDidMount();
		document.addEventListener("keydown", this._handleKeyDown);
		ChartJS.register(
		  CategoryScale,
		  LinearScale,
		  PointElement,
		  LineElement,
		  Title,
		  Tooltip,
		  Filler,
		  Legend
		);
		ChartJS.defaults.color = 'rgb(255, 175, 255)';
		ChartJS.defaults.borderColor = 'rgba(255, 175, 255, 0.2)';
	}
	componentWillUnmount() {
		document.removeEventListener("keydown", this._handleKeyDown);
	}

	render() {
		return (
		<ToastProvider>
			<Message></Message>
			<MidokuniNavBar />
			{/* overflow: hidden will break Sticky */}
			<Container fluid className="bg-body-secondary" data-bs-theme="dark" style={{flexGrow:1, maxWidth:"100vw"}}>
				<Row style={{minHeight:"calc(100vh - 71px)"}}>
					<Col>
						<Row>
							<Col>
								{/* <Alert dismissible key="danger" variant="danger">Mostly still wip and is just barebones but feel free to use. Expect sudden changes tho.</Alert> */}
							</Col>
						</Row>
						<Row className="content">
							<Col className="text-light">
								<Row style={{marginTop:10}}><Col>{this.props.children}</Col></Row>
							</Col>
						</Row>
						{!!!this.props.hideFooter && <MyBrandFooter />}
					</Col>
				</Row>
			</Container>
		</ToastProvider>
		);
	}
}
	