import IconButton from "Components/IconButton";
import { Images } from "Components/Images";
import StudentRenderer from "Components/ModelRenderers/StudentRenderer";
import MultiStudentPicker from "Components/MultiStudentPicker";
import IStyle from "Interfaces/IStyle";
import { useToast } from "Layouts/Toast";
import { SchaleStudent } from "Models/Student";
import StudentMap from "Models/StudentMap";
import { copyToClipboard } from "Utils/Common";
import MersenneTwister from "mersenne-twister";
import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import { Accordion, Col, Form, Row, Table } from "react-bootstrap";
import AccordionBody from "react-bootstrap/esm/AccordionBody";
import AccordionHeader from "react-bootstrap/esm/AccordionHeader";

interface IMatchResult {
	Match: boolean,
	Message?: string
}
interface IComparator {
	(a: any, b: any): IMatchResult;
}
const styles: IStyle = {
	cell: {
		alignContent: "center", 
		alignItems: "center", 
		verticalAlign: "center",
	},
	flex: {
		display: "flex",
		flexDirection: "row",
	}
}
const DEFAULT_COMPARATOR:IComparator = (a, b):IMatchResult => {
	return {
		Match: a == b,
		Message: a
	}
}
const BEFORE_AFTER_COMPARATOR:IComparator = (target, toCompare):IMatchResult => {
	const match = target == toCompare;
	return {
		Match: match,
		Message: match ? "You got it" : (target < toCompare ? "During or After" : "During or Before")
	}
}
interface IComparators {
	[field:string]: IComparator
}
const COMPARATORS: IComparators = {
	"DefaultOrder": BEFORE_AFTER_COMPARATOR,
}
const FIELD_ALIAS: {[field:string]: string} = {
	"DefaultOrder": "Release Date (Goal is...)",
	"BulletType": "Attack Type",
	"WeaponType": "Weapon Type",
	"BirthMonth": "Birth Month",
	"TacticRole": "Role",
	"ArmorType": "Armor Type",
	"CharacterAge": "Age",
	"StarGrade": "Stars",
	"PersonalName": "Given Name",
	"FamilyName": "Family Name",

}
const DEFAULT_FIELDS = [
	"Name", 
	"WeaponType", 
	"TacticRole",
	"BulletType", 
	"School", 
	"DefaultOrder",
]
const AVAILABLE_FIELDS = [...new Set([...DEFAULT_FIELDS,
	...["Name", 
	"Costume", 
	"WeaponType", 
	"TacticRole",
	"BulletType", 
	"School", 
	"BirthMonth",
	"DefaultOrder",
	"ArmorType",
	"CharacterAge",
	"Club",
	"Illustrator",
	"Range",
	"PersonalName",
	"FamilyName",
	"StarGrade"].sort()
])]
const HINT_FIELDS = [
	"DefaultOrder"
]
const KEYS = {
	DAILY_DATE: "midokuni.kivodle.date",
	DAILY_SET: "midokuni.kivodle.set",
	DAILY_ANSWERS: "midokuni.kivodle.answer",
	DAILY_STREAK: "midokuni.kivodle.streak",
}

const now = new Date();
const strNow = moment(now).utc().format("YYYY MMM DD");
const seedNow = now.getUTCFullYear() * 10000 + now.getUTCMonth() * 100 + now.getUTCDate();
const DEFAULT_MAX_ATTEMPTS = 5;
type IKivodleMode = "Daily" | "Endless" | "Custom Endless"
const GAMEMODES:IKivodleMode[] = ["Daily", "Endless", "Custom Endless"]

interface IGame {
	answers: SchaleStudent[];
	target: SchaleStudent;
}

const smap = new StudentMap();

export default function Kivodle() {
	const [studentDataset, setStudentDataset] = useState<SchaleStudent[]>(StudentMap.schaleStudent);
	const DAILY_DATE = localStorage.getItem(KEYS.DAILY_DATE)
	const DAILY_SET = parseInt(localStorage.getItem(KEYS.DAILY_SET) ?? studentDataset.length.toString())
	const DAILY_STREAK = parseInt(localStorage.getItem(KEYS.DAILY_STREAK) ?? "0")
	const [isLastStudentStanding, setIsLastStudentStanding] = useState(false);
	const [games, setGames] = useState<IGame[]>([]);
	const [target, setTarget] = useState<SchaleStudent>(null);
	const [fields, setFields] = useState<string[]>(DEFAULT_FIELDS);
	const [maxAttempts, setMaxAttempts] = useState(DEFAULT_MAX_ATTEMPTS);

	const options = useMemo(() => Object.keys(StudentMap.revMap).map(id => smap.getProperName(id)).filter(s=>studentDataset.find(s2=>s2.id === smap.getIdFromUnknown(s))), [studentDataset]).sort();
	// const initialDailyAnswers:SchaleStudent[] = useMemo(()=>{
	// }, [DAILY_ANSWERS])
	// console.log(target)
	const [answers, setAnswers] = useState<SchaleStudent[]>([]);
	const formatAnswers = useMemo(()=>{
		return answers.map( answer => {
			// console.log([answer, target])
			return fields.map(field => {
				const comparator = COMPARATORS[field] ?? DEFAULT_COMPARATOR;
				const result = comparator(answer[field], target[field]);
				return result.Match ? "🟩" : "🟥";
			}).join("")
		}).join("\n");
	}, [answers]);
	const isWin = target && answers.length > 0 && target.name === answers[0].name;
	const isLose = !isWin && maxAttempts <= answers.length;
	const [streak, setStreak] = useState(0);
	// const [prevStreak, setPrevStreak] = useState(0);
	const [gameMode, setGameMode] = useState<IKivodleMode>("Daily");
	const toast = useToast();
	function resetGame() {
		if (isWin) {
			setStreak(streak+1);
		} else {
			setStreak(0);
		}
		if (maxAttempts !== DEFAULT_MAX_ATTEMPTS && gameMode !== "Custom Endless") {
			setMaxAttempts(DEFAULT_MAX_ATTEMPTS);
		}
		if (gameMode === "Daily") {
			setFields(DEFAULT_FIELDS);
			setStudentDataset(StudentMap.schaleStudent)
			const DAILY_ANSWERS:string[] = JSON.parse(localStorage.getItem(KEYS.DAILY_ANSWERS) ?? "[]")
			if (DAILY_DATE === seedNow.toString() && studentDataset.length === DAILY_SET)
				setAnswers(DAILY_ANSWERS.map(a=>studentDataset.find(s=>s.name === a)))
			else
				setAnswers([]);
		} else {
			if (isLastStudentStanding) {
				if (isWin) {
					const answerIds = answers.map(a => a.id);
					setGames([{answers, target}, ...games])
					setStudentDataset(studentDataset.filter(s => answerIds.indexOf(s.id) < 0));
				} else {
					setGames([])
					setStudentDataset(StudentMap.schaleStudent)
				}
			} else {
				setStudentDataset(StudentMap.schaleStudent)
			}
			setAnswers([]);
		}
		randomizeTarget();
	}

	useEffect(() => {
		let ignore = false;
		if (!!!ignore) {
			resetGame();
			setStudentDataset(StudentMap.schaleStudent)
			setGames([]);
			setIsLastStudentStanding(false);
		}

		return () => {
			ignore = true
		}
	}, [fields, gameMode, maxAttempts])

	function randomizeTarget() {
		function getSeed() {
			if (gameMode === "Daily")
				return seedNow;
			return Date.now();
		}
		const seed = getSeed();
		const mt = new MersenneTwister(seed);
		
		setTarget(studentDataset[Math.floor(mt.random() * studentDataset.length)]);
		
	}
	useEffect(() => {
		let ignore = false;
		if (!!!ignore) {
			randomizeTarget();
		}

		return () => {
			ignore = true
		}
	}, [])
	const addAnswer = (item: string) => {
		if ( answers.length >= maxAttempts) {
			toast.addToast("You've already used up all your attempts.")
			return;
		}
		if ( isWin ) {
			return;
		}
		const id = new StudentMap().getIdFromUnknown(item);
		const schaleStudent = StudentMap.schaleStudent.find(s => s.id == id);
		if (!!!schaleStudent) {
			toast.addToast("Student is not yet in SchaleDB. Please pick a different student.");
			return;
		}
		const newAnswer = studentDataset.find(s => s.id == id);
		if (!!!newAnswer) {
			toast.addToast("Student has been picked already. Please pick a different student.");
			return;
		}
		
		const tempAnswer = [newAnswer, ...answers];
		setAnswers(tempAnswer)
		if (gameMode === "Daily") {
			localStorage.setItem(KEYS.DAILY_SET, studentDataset.length.toString());
			localStorage.setItem(KEYS.DAILY_DATE, seedNow.toString());
			localStorage.setItem(KEYS.DAILY_ANSWERS, JSON.stringify(tempAnswer.map(a => a.Name)));
			if (newAnswer.id === target.id)
				localStorage.setItem(KEYS.DAILY_STREAK, (DAILY_STREAK+1).toString());
			else if (isLose)
				localStorage.setItem(KEYS.DAILY_STREAK, "0");
		}
		(window.document.querySelector("input.search") as any)?.focus();
	}
	interface IAnswerProps {
		answer: SchaleStudent;
		target?: SchaleStudent;
	}
	function AnswerStudent(props: IAnswerProps) {
		const {answer} = props;

		const gameTarget = props.target ?? target;
		if (!!!answer || !!!gameTarget) return;
		
		return (<>
			<td className="d-none d-md-block" width="0"><Row><Col style={{...styles.flex}}  className="justify-content-center responsive-font"><StudentRenderer compact bare model={answer} /></Col></Row></td>
			{fields.map(field => {
				const comparator = COMPARATORS[field] ?? DEFAULT_COMPARATOR;
				const result = comparator(answer[field], gameTarget[field]);
				const isHint = HINT_FIELDS.indexOf(field) >= 0;
				return (<td key={`${field}_${answer}`} style={styles.cell} width="0">
					<Row><Col style={styles.flex} className="justify-content-center"><Images.Mood mood={result.Match ? "SS" : isHint ? "B" : "D"}></Images.Mood></Col></Row>
					<Row><Col style={styles.flex} className="justify-content-center responsive-font">{result.Message ?? (result.Match ? "TRUE" : "FALSE")}</Col></Row>
				</td>);
			})}
		</>);
	}

	function ResultsContainer() {
		if (!isWin && !isLose) return;
		const isDaily = gameMode === "Daily";
		const resultText = isWin
			? (isDaily ? `You win! Current Streak: ${DAILY_STREAK}` : (
				isLastStudentStanding && answers.length === studentDataset.length 
					? "You win! All students have been picked!" 
					: `You win! Current Streak: ${streak+1}`
			))
			: (isDaily ? "You lose!" : `You lose! Your Win Streak: ${streak}`)
		return (<>
		{resultText}
		<Row>
			{ gameMode === "Daily" && <Col style={styles.buttonLink}>
				<IconButton 
					image="/logo512.png"
					label="Copy"
					onClick={async () => {
						const shareText = `Daily Kivodle ${strNow} [v${studentDataset.length}]\nI did ${answers.length} attempts and ${isWin ? "won" : "lost"}\n\n${formatAnswers}\n\n${window.location.href}`;
						copyToClipboard(shareText);
						toast.addToast("Results have been put in your clipboard!")
					}}
				/>
			</Col>}
			{ gameMode === "Daily" && <Col style={styles.buttonLink}>
				<IconButton 
					image="/logo512.png"
					label="Copy (No Emoji)"
					onClick={async () => {
						
						const shareText = `Daily Kivodle ${strNow} [v${studentDataset.length}]\nI did ${answers.length} attempts and ${isWin ? "won" : "lost"}\n\n${formatAnswers
							// .replaceAll("🟥","\\🟥").replaceAll("🟩","\\🟩")
							.replaceAll("🟥","○").replaceAll("🟩","●")
						}\n\n${window.location.href}`;
						copyToClipboard(shareText);
						toast.addToast("Results have been put in your clipboard!")
					}}
				/>
			</Col>}
			{ gameMode === "Daily" && <Col style={styles.buttonLink}>
				<IconButton 
					image="https://www.google.com/s2/favicons?sz=64&domain=x.com"
					label="Share on X"
					onClick={async () => {
						// resetGame();
						const shareText = `Daily Kivodle ${strNow} [v${studentDataset.length}]\nI did ${answers.length} attempts and ${isWin ? "won" : "lost"}\n${formatAnswers}\n`;
						window.open(`https://x.com/intent/tweet?text=${encodeURI(shareText)}&url=${encodeURI(window.location.href)}&hashtags=Kivodle`, '', 'menubar=no,toolbar=no,resizable=no,scrollbars=no,height=500,width=600');
					}}
				/>
			</Col>}
			{ gameMode !== "Daily" && <Col style={styles.buttonLink}>
				<IconButton 
					image="/logo512.png"
					label="Next Round"
					onClick={async () => {
						resetGame();
					}}
				/>
			</Col>}
		</Row>
		</>);
	}

	function CustomEndlessOptions() {
		return (
			<Accordion.Item eventKey="0">
				<AccordionHeader><h2>Custom Endless Options</h2></AccordionHeader>
				<AccordionBody>
					<Row>
						<Col>
							<Row><Col><h4 className="text-pink">Max Number of Attempts</h4></Col></Row>
							<Row><Col><input type="number" min={1} step={1} value={maxAttempts} onChange={(e)=>setMaxAttempts(parseInt(e.target.value))}/></Col></Row>
							<Row><Col><h4 className="text-pink">Included Columns</h4></Col></Row>
							{AVAILABLE_FIELDS.map(fieldid => {
								const field = FIELD_ALIAS[fieldid] ?? fieldid;
								return <Row><Col><Form.Check // prettier-ignore
									defaultChecked={fields.indexOf(fieldid) >= 0}
									onClick={()=>{
										if (fields.indexOf(fieldid) >= 0) {
											setFields(fields.filter(f => f !== fieldid))
										} else {
											setFields([...new Set([...fields, fieldid])])
										}
									}}
									key={`fields_${field}`}
									type="radio"
									id={`fields_${field}`}
									label={field}
									// label={<TagBadge>{a}</TagBadge>}
								/></Col></Row>
							})}
							<Row><Col><h4 className="text-pink">KIVODLE: LAST STUDENT STANDING</h4></Col></Row>
							<Row><Col><Form.Check 
								defaultChecked={isLastStudentStanding}
								onClick={()=>{
									setIsLastStudentStanding(!isLastStudentStanding)
								}}
								key={`fields_LastStudentStanding`}
								type="switch"
								id={`fields_LastStudentStanding`}
								label="Last Student Standing"
							/></Col></Row>
							<Row><Col><p>In this game mode, each student can only be picked at most one time. Note that once a student has been picked, they will be excluded from the possible targets. You will win once you manage to pick every single student in the database. The idea is that if you plan your picks well, you can still get a high streak.</p></Col></Row>
						</Col>
					</Row>
				</AccordionBody>
			</Accordion.Item>
		);
	}



	return (<>
	<Row>
		<Col>
			<Row>
				<Col>
					<h1 className="text-pink">Kivodle</h1>
				</Col>
			</Row>
			<Row>
				<Col xs={2}><label>Game Mode</label></Col>
				<Col>
					<Row>
						{GAMEMODES.map(oGameMode => {
							return <Col><Form.Check // prettier-ignore
								checked={gameMode === oGameMode}
								// disabled
								onSelect={()=>{
									setGameMode(oGameMode)
								}}
								onChange={()=>{
									setGameMode(oGameMode)
								}}
								key={`gameMode_${oGameMode}`}
								type="radio"
								id={`gameMode_${oGameMode}`}
								label={oGameMode}
								// label={<TagBadge>{a}</TagBadge>}
							/></Col>
						})}	
					</Row>
				</Col>
			</Row>
			{ gameMode === "Custom Endless" && <Row><Col>
				<Accordion defaultActiveKey="0"><CustomEndlessOptions /></Accordion></Col></Row>}
			<Row>
				<Col>
					<MultiStudentPicker options={options} hasFull setTarget={null} pop={null} target={[]} push={addAnswer}></MultiStudentPicker>
				</Col>
			</Row>
			{!!!isWin && !!!isLose && (gameMode === "Daily" ? `Current Daily Streak: ${DAILY_STREAK}` : `Current Win Streak: ${streak}`)}
			<ResultsContainer></ResultsContainer>
			<Row>
				<Col>
					<Table responsive variant="violet" striped hover>
						<thead>
							<tr>
								<td className="d-none d-md-block" width="0"><Row><Col style={{...styles.flex}} className="justify-content-center responsive-font"><strong>Student</strong></Col></Row></td>
								{fields.map(field => {
									return <td width="0"><Row><Col style={styles.flex} className="justify-content-center responsive-font"><strong>{FIELD_ALIAS[field] ?? field}</strong></Col></Row></td>
								})}
							</tr>
						</thead>
						<tbody >
							{isLose && <tr><AnswerStudent answer={target} /></tr>}
							{answers?.map((d, i) => (<tr key={`${i}_answer`}>
								<AnswerStudent answer={d} />
							</tr>))}
							{isLastStudentStanding && games.map((game, i) => {
								return (
									<>
									<tr key={`${i}_hint`}><AnswerStudent answer={game.answers?.[0]} target={i > 0 ? games[i-1].target : target}/></tr>
									{game.answers?.map((d, j) => (<tr key={`${i}_${j}_answer`}>
										<AnswerStudent answer={d} target={game.target}/>
									</tr>))}
									</>
								)
							})}
						</tbody>
					</Table>
				</Col>
			</Row>
		</Col>
	</Row>
	<Row className="responsive-font"><Col>This has been inspired by <a href="https://taktstockjp.github.io/Kivodle/">https://taktstockjp.github.io/Kivodle/</a>. The data used here is processed from <a href="https://schaledb.com">schaledb.com</a></Col></Row>
	</>);
}