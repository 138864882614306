import StudentMap from "Models/StudentMap";
import { APRIL_FOOLS_MODE, isAprilFools, reduceInitials } from "Utils/Common";


const HINAS = ["Hina", "Hina (Swimsuit)", "Hina (Dress)"]
export default class Student{
	name: string;
	id: string | number;
	mystic: number = 1;
	ue: number = 0;
	level: number = 1;
	isUe: boolean = false;
	isAssist: boolean = false;
	unit: number = 0;
	isSkillSelected: boolean = false;
	isEmpty() {
		return !!!this.id;
	}

	getId() {
		return this.isEmpty() ? "EMPTY" : this.id;
	}
	
	getIcon() {
		if (isAprilFools() && HINAS.indexOf(this.name) < 0) {
			if (APRIL_FOOLS_MODE === 0) { 
				if (Math.floor(Math.random() * 100) < 20) {
					switch (Math.floor(Math.random() * 4)) {
						case 0: return "/img/DefaultStudent.jpg";
						case 1: return new StudentMap().getIcon("Hina");
						case 2: return new StudentMap().getIcon("Hina (Swimsuit)");
						case 3: return new StudentMap().getIcon("Hina (Dress)");
					}
				}
			} else {
				if ((Math.random() * 100) < 3)
					return "/img/Envelope_Pink.gif";
				else
					return "/img/Envelope_Blue.png";
			}
		}
		const icon = new StudentMap().getIcon(this.name);
		if ("" === icon) return "/img/DefaultStudent.jpg"
		return icon;
	}
	getName() {
		return this.name ?? "EMPTY";
	}
	getDisplayName() {
		const r = /\s*\(.+?\)$/g
		let name = this.getName();
		const matches = name.match(r);
		if (!!!matches)
			return name;
		return `${matches[0].replaceAll(/\s+[\(\)]/g, "").split(" ").reduce(reduceInitials, '')}.${name.replaceAll(r, "")}`;
		
	}
	getTier() {
		if (this.isEmpty()) return '';
		if (this.isUe) {
			if (this.ue > 0)
				return `${this.ue}★UE`;
			return 'UE';
		}

		return `${this.mystic}☆`;
	}
	getUeTier() {
		if (this.isEmpty()) return '';
		if (this.isUe && this.ue > 0) {
			return `${this.ue}★`;
		}

		return ``;
	}
	getTierNumber() {
		if (this.isEmpty()) return 0;
		if (this.isUe) {
			if (this.ue > 0)
				return this.ue+5;
			return 6;
		}

		return this.mystic;
	}
	getLevel() {
		if (this.isEmpty()) return 0;
		return this.level
	}
	toString() {
		if(this.isEmpty())
			return "EMPTY";
		const assist = this.isAssist ? ' (Assist)' : '';

		return `${this.getTier()} ${this.getName()} Lv${this.level}${assist}`;;
	}

	static fromId(id: string): Student {
		const student = new Student();
		const map = new StudentMap();
		student.name = map.getProperName(id);
		student.id = id;

		return student;
	}
	static fromName(name: string): Student {
		const student = new Student();
		const map = new StudentMap();
		student.name = map.getProperName(name);
		student.id = map.getId(student.name);

		return student;
	}

	static fromRaidRanks(data: string): Student {
		const student = new Student();
		const isSkill = data.startsWith("!");
		if (isSkill) {
			data = data.substring(1);
			student.isSkillSelected = isSkill;
		}
		student.isAssist = data.endsWith("(A)");
		// WORKAROUND: old browsers not supporting lookbehind
		let match = data.match(/Lv(\d+)/g) ?? ['Lv1']
		// let match = data.match(/(?<=Lv)(\d+)/g) ?? ['1']
		student.level = parseInt(match[0].replace("Lv",""));
		const map = new StudentMap();
		if(data.match(/UE/g)) {
			student.isUe = true;
			student.mystic = 5;
			student.name = map.getProperName(data.replaceAll(/ (\d+\*)?UE.+/g,'').trim());
			const ueMatch = data.match(/(\d+)\*UE/gi);
			if (ueMatch) {
				student.ue = parseInt(ueMatch[0].split("*")[0]);
			}
		} else {
			// WORKAROUND: old browsers not supporting lookahead
			match = data.match(/(\d+)\*/g) ?? ['1*']
			// match = data.match(/(\d+)(?=\*)/g) ?? ['1']
			student.mystic = parseInt(match[0].replace("*",""));
			student.name = map.getProperName(data.replaceAll(/ \d\*.+/g,'').trim());
		}
		student.id = map.getId(student.name);

		return student;
	}
	static fromTowerTeams(data: string): Student {
		const student = new Student();
		if (!data) return undefined;
		const isSkill = data.startsWith("!");
		if (isSkill) {
			data = data.substring(1);
			student.isSkillSelected = isSkill;
		}
		student.isAssist = data.endsWith("(A)");
		// WORKAROUND: old browsers not supporting lookbehind
		let match = data.match(/Lv(\d+)/g) ?? ['Lv1']
		// let match = data.match(/(?<=Lv)(\d+)/g) ?? ['1']
		student.level = parseInt(match[0].replace("Lv",""));
		const map = new StudentMap();
		if(data.match(/UE/g)) {
			student.isUe = true;
			student.mystic = 5;
			student.name = map.getProperName(data.replaceAll(/ UE.+/g,'').trim());
		} else {
			// WORKAROUND: old browsers not supporting lookahead
			match = data.match(/(\d+)\*/g) ?? ['1*']
			// match = data.match(/(\d+)(?=\*)/g) ?? ['1']
			student.mystic = parseInt(match[0].replace("*",""));
			student.name = map.getProperName(data.replaceAll(/ \d\*.+/g,'').trim());
		}
		student.id = map.getId(student.name);

		return student;
	}
}

export interface ISchaleStudent {
	AccuracyPoint: number;
	AmmoCost: number;
	AmmoCount: number;
	ArmorType: string;
	AttackPower1: number;
	AttackPower100: number;
	BirthDay: string;
	Birthday: string;
	BulletType: string;
	CharHeightImperial: string;
	CharHeightMetric: string;
	CharacterAge: string;
	CharacterSSRNew: string;
	CharacterVoice: string;
	Club: string;
	CollectionBG: string;
	Cover: true;
	CriticalDamageRate: number;
	CriticalPoint: number;
	DefaultOrder: number;
	DefensePower1: number;
	DefensePower100: number;
	Designer: string;
	DevName: string;
	DodgePoint: number;
	Equipment: string[];
	FamilyName: string;
	FavorAlts: number[];
	FavorItemTags: string[];
	FavorItemUniqueTags: string[];
	FavorStatType: string[];
	FavorStatValue: number[][];
	FurnitureInteraction: any[][];
	Gear: any;
	HealPower1: number;
	HealPower100: number;
	Hobby: string;
	Icon: string;
	Id: number;
	Illustrator: string;
	IndoorBattleAdaptation: number;
	IsLimited: number;
	IsReleased: [
		jp: boolean,
		global: boolean,
		cn: boolean,
	];
	MaxHP1: number;
	MaxHP100: number;
	MemoryLobby: number[];
	MemoryLobbyBGM: number;
	Name: string;
	OutdoorBattleAdaptation: number;
	PathName: string;
	PersonalName: string;
	Position: string;
	PotentialMaterial: number;
	ProfileIntroduction: string;
	Range: number;
	RegenCost: number;
	School: string;
	SchoolYear: string;
	SearchTags: string[];
	Size: string;
	SkillExMaterial: number[][];
	SkillExMaterialAmount: number[][];
	SkillMaterial: number[][];
	SkillMaterialAmount: number[][];
	Skills: any
	SquadType: string;
	StabilityPoint: number;
	StarGrade: number;
	StreetBattleAdaptation: number;
	Summons: any[];
	TacticRole: string;
	Weapon: any
	WeaponImg: string;
	WeaponType: string;
}

export class SchaleStudent extends Student implements ISchaleStudent {
	AccuracyPoint: number;
	AmmoCost: number;
	AmmoCount: number;
	ArmorType: string;
	AttackPower1: number;
	AttackPower100: number;
	BirthDay: string;
	Birthday: string;
	BulletType: string;
	CharHeightImperial: string;
	CharHeightMetric: string;
	CharacterAge: string;
	CharacterSSRNew: string;
	CharacterVoice: string;
	Club: string;
	CollectionBG: string;
	Cover: true;
	CriticalDamageRate: number;
	CriticalPoint: number;
	DefaultOrder: number;
	DefensePower1: number;
	DefensePower100: number;
	Designer: string;
	DevName: string;
	DodgePoint: number;
	Equipment: string[];
	FamilyName: string;
	FavorAlts: number[];
	FavorItemTags: string[];
	FavorItemUniqueTags: string[];
	FavorStatType: string[];
	FavorStatValue: number[][];
	FurnitureInteraction: any[][];
	Gear: any;
	HealPower1: number;
	HealPower100: number;
	Hobby: string;
	Icon: string;
	Id: number;
	Illustrator: string;
	IndoorBattleAdaptation: number;
	IsLimited: number;
	IsReleased: [jp: boolean, global: boolean, cn: boolean];
	MaxHP1: number;
	MaxHP100: number;
	MemoryLobby: number[];
	MemoryLobbyBGM: number;
	Name: string;
	OutdoorBattleAdaptation: number;
	PathName: string;
	PersonalName: string;
	Position: string;
	PotentialMaterial: number;
	ProfileIntroduction: string;
	Range: number;
	RegenCost: number;
	School: string;
	SchoolYear: string;
	SearchTags: string[];
	Size: string;
	SkillExMaterial: number[][];
	SkillExMaterialAmount: number[][];
	SkillMaterial: number[][];
	SkillMaterialAmount: number[][];
	Skills: any;
	SquadType: string;
	StabilityPoint: number;
	StarGrade: number;
	StreetBattleAdaptation: number;
	Summons: any[];
	TacticRole: string;
	Weapon: any;
	WeaponImg: string;
	WeaponType: string;

	BirthMonth: string;
	Costume: string;
	
	constructor(data:ISchaleStudent) {
		super();
		this.name = data.Name;
		this.AccuracyPoint = data.AccuracyPoint;
		this.AmmoCost = data.AmmoCost;
		this.AmmoCount = data.AmmoCount;
		this.ArmorType = data.ArmorType;
		switch (this.ArmorType) {
			case "LightArmor": this.ArmorType = "Light"; break;
			case "HeavyArmor": this.ArmorType = "Heavy"; break;
			case "Unarmed": this.ArmorType = "Special"; break;
			case "ElasticArmor": this.ArmorType = "Elastic"; break;
		}
		this.AttackPower1 = data.AttackPower1;
		this.AttackPower100 = data.AttackPower100;
		this.BirthDay = data.BirthDay;
		this.Birthday = data.Birthday;
		this.BulletType = data.BulletType;
		switch (this.BulletType) {
			case "Explosion": this.BulletType = "Explosive"; break;
			case "Pierce": this.BulletType = "Piercing"; break;
		}
		this.CharHeightImperial = data.CharHeightImperial;
		this.CharHeightMetric = data.CharHeightMetric;
		this.CharacterAge = data.CharacterAge;
		this.CharacterSSRNew = data.CharacterSSRNew;
		this.CharacterVoice = data.CharacterVoice;
		this.Club = data.Club;
		this.CollectionBG = data.CollectionBG;
		this.Cover = data.Cover;
		this.CriticalDamageRate = data.CriticalDamageRate;
		this.CriticalPoint = data.CriticalPoint;
		this.DefaultOrder = data.DefaultOrder;
		this.DefensePower1 = data.DefensePower1;
		this.DefensePower100 = data.DefensePower100;
		this.Designer = data.Designer;
		this.DevName = data.DevName;
		this.DodgePoint = data.DodgePoint;
		this.Equipment = data.Equipment;
		this.FamilyName = data.FamilyName;
		this.FavorAlts = data.FavorAlts;
		this.FavorItemTags = data.FavorItemTags;
		this.FavorItemUniqueTags = data.FavorItemUniqueTags;
		this.FavorStatType = data.FavorStatType;
		this.FavorStatValue = data.FavorStatValue;
		this.FurnitureInteraction = data.FurnitureInteraction;
		this.Gear = data.Gear;
		this.HealPower1 = data.HealPower1;
		this.HealPower100 = data.HealPower100;
		this.Hobby = data.Hobby;
		this.Icon = data.Icon;
		this.Id = data.Id;
		this.id = data.Id;
		this.Illustrator = data.Illustrator;
		this.IndoorBattleAdaptation = data.IndoorBattleAdaptation;
		this.IsLimited = data.IsLimited;
		this.IsReleased = data.IsReleased;
		this.MaxHP1 = data.MaxHP1;
		this.MaxHP100 = data.MaxHP100;
		this.MemoryLobby = data.MemoryLobby;
		this.MemoryLobbyBGM = data.MemoryLobbyBGM;
		this.Name = data.Name;
		this.OutdoorBattleAdaptation = data.OutdoorBattleAdaptation;
		this.PathName = data.PathName;
		this.PersonalName = data.PersonalName;
		this.Position = data.Position;
		this.PotentialMaterial = data.PotentialMaterial;
		this.ProfileIntroduction = data.ProfileIntroduction;
		this.Range = data.Range;
		this.RegenCost = data.RegenCost;
		this.School = data.School;
		this.SchoolYear = data.SchoolYear;
		this.SearchTags = data.SearchTags;
		this.Size = data.Size;
		this.SkillExMaterial = data.SkillExMaterial;
		this.SkillExMaterialAmount = data.SkillExMaterialAmount;
		this.SkillMaterial = data.SkillMaterial;
		this.SkillMaterialAmount = data.SkillMaterialAmount;
		this.Skills = data.Skills;
		this.SquadType = data.SquadType;
		this.StabilityPoint = data.StabilityPoint;
		this.StarGrade = data.StarGrade;
		this.StreetBattleAdaptation = data.StreetBattleAdaptation;
		this.Summons = data.Summons;
		this.TacticRole = data.TacticRole;
		switch (this.TacticRole) {
			case "DamageDealer": this.TacticRole = "Dealer"; break;
			case "Supporter": this.TacticRole = "Support"; break;
			case "Tanker": this.TacticRole = "Tank"; break;
			case "Vehicle": this.TacticRole = "T.S."; break;
		}
		this.Weapon = data.Weapon;
		this.WeaponImg = data.WeaponImg;
		this.WeaponType = data.WeaponType;

		if (this.name.indexOf("(") > 0) {
			this.Costume = this.name.replaceAll(/(^.+\()|\)$/g, "")
		} else {
			this.Costume = "Original"
		}
		
		try {
			this.BirthMonth = data.Birthday.split(" ")[0];
		} catch {
			this.BirthMonth = "Unknown";
		}
	}

	isReleased(server:IGameServer) {
		switch (server) {
			case "jp": return this.IsReleased[0];
			case "global": return this.IsReleased[1];
			case "cn": return this.IsReleased[2];
		}
		return false;
	}
}
export type IGameServer = "jp" | "global" | "cn";

export interface IAronaSprite {
	key: number;
	nameJP: string;
	nameKR: string;
	nicknameJP: string;
	nicknameKR: string;
	portrait: string;
	spinePrefab: string;
}

export class AronaStudent extends Student implements IAronaSprite {
	key: number;
	nameJP: string;
	nameKR: string;
	nicknameJP: string;
	nicknameKR: string;
	portrait: string;
	spinePrefab: string;

	constructor(data:IAronaSprite) {
		super();
		this.name = data.nameJP;
		this.key = data.key;
		this.nameJP = data.nameJP;
		this.nameKR = data.nameKR;
		this.nicknameJP = data.nicknameJP;
		this.nicknameKR = data.nicknameKR;
		this.portrait = data.portrait;
		this.spinePrefab = data.spinePrefab;
	}

	override getIcon() {
		// https://media.arona.ai/images/v1/public/uis/01_common/01_character/student_portrait_hasumi/icon4x
		if (isAprilFools() && HINAS.indexOf(this.name) < 0) {
			switch (Math.floor(Math.random() * 4)) {
				case 0: return "/img/DefaultStudent.jpg";
				case 1: return new StudentMap().getIcon("Hina");
				case 2: return new StudentMap().getIcon("Hina (Swimsuit)");
				case 3: return new StudentMap().getIcon("Hina (Dress)");
			}
		}
		// const icon = new StudentMap().getIcon(this.name);
		// if ("" === icon) return "/img/DefaultStudent.jpg"
		return ""
		return `https://media.arona.ai/images/v1/public/${this.portrait.toLowerCase()}/icon4x`;
	}
}


export class NumberedStudent extends Student {
	public num?: number = 1;
	static fromNameWithNumber(name: string, num: number): Student {
		const student = new NumberedStudent();
		const map = new StudentMap();
		student.name = map.getProperName(name);
		student.id = map.getId(student.name);
		student.num = num;
		return student;
	}

	override getDisplayName(): string {
		return this.num+". "+super.getDisplayName();
	}
}