import { Images } from "Components/Images";
import StudentRenderer from "Components/ModelRenderers/StudentRenderer";
import MyBrand from "Components/MyBrand";
import MyLoadingSpinner from "Components/MyLoadingSpinner";
import RaidTerrainImage from "Components/RaidTerrainImage";
import { StudentPicker } from "Components/StudentPicker";
import { BUCKET_HOST, SCORE_CALCULATORS } from "Constants/Common";
import IStyle from "Interfaces/IStyle";
import { useToast } from "Layouts/Toast";
import RaidScore from "Models/RaidScore";
import Student, { NumberedStudent } from "Models/Student";
import StudentMap from "Models/StudentMap";
import { focusTextBox, getJson, secondsToTime, sortByLevenshteinDistance } from "Utils/Common";
import domToImage from 'dom-to-image';
import React, { useEffect, useMemo, useState } from "react";
import { Alert, Button, CloseButton, Col, Form, Row } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { IJustinTournamentData } from "../../Interfaces/IJustinTournamentData";

const UNMATCH_VISIBLE = false;
const MAX_RESULTS = 50;
const styles:IStyle = {
	icon: {
		textAlign: "center"
	},
	dim: {
		opacity: 0.2
	},
	container: {
		padding: 10,
		margin: 5,
		border: "1px solid",
		// borderColor: "violet",
		borderRadius: 20,
		borderColor: "pink",
	},
	brand: {
		marginTop: 10
	},
	closeButton: {
		textAlign: "right"
	},
	highlight: {
		border: "10px solid",
		// borderColor: "violet",
		borderRadius: 20,
		borderColor: "pink",
	}
}
interface IRaidInfo {
	season: number,
	playable: {[name:string]: any},
	start: string,
	end: string,
	ban?: {
		qualifiers: string[]
	}
	seasonType: any
}
interface IBanProps {
	banNumber: number;
	player1: string;
	player2: string;
	forcedPick: string,
	playerBans,
	setPlayerBans,
}
const studentMap = new StudentMap();
// https://wheelofnames.com/api/v1/wheels/shared/755-pt7
type IArmorType = "Light" | "Heavy" | "Special" | "Elastic";
type IStages = "Qualifiers" | "Groups" | "Finals";
const LSTORAGE_ROOT = "midokuni.grand.tournament.";
const DEFAULT_QUALIFIER_BANS:string[] = [
	"Wakamo", 
	"Hoshino (Swimsuit)", 
	"Mika", 
	"Hanako (Swimsuit)", 
	"Hina (Dress)",
];
interface IProps {
	template?: IJustinTournamentData;
}
export default function GrandTournament(props: IProps) {
	const {template} = props;
	const init:IJustinTournamentData = useMemo(() => {
		if (!template) return undefined;
		let stage:IStages = "Qualifiers";
		console.log(template);
		switch (template?.Stage) {
			case "qualifiers": stage = "Qualifiers"; break;
			case "groups": stage = "Groups"; break;
			case "finals": stage = "Finals"; break;
			case "elimination": stage = "Finals"; break;
			default: stage = "Qualifiers"; break;
		}
		const toRet = {
			...template,
			Game: template.Game.substring(5),
			Armour: template.Armour[0].toUpperCase() + template.Armour.substring(1),
			Tournament: template.Tournament.substring(11),
			Stage: stage,
			ForcePick: studentMap.getProperName(template.ForcePick) ?? "EMPTY",
			Player1Bans: template.Player1Bans.map((ban) => studentMap.getProperName(ban) ?? "EMPTY"),
			Player2Bans: template.Player2Bans.map((ban) => studentMap.getProperName(ban) ?? "EMPTY"),
			Player1Teams: template.Player1Teams.map((team) => team.map((stud) => studentMap.getProperName(stud) ?? "EMPTY")),
			Player2Teams: template.Player2Teams.map((team) => team?.map((stud) => studentMap.getProperName(stud) ?? "EMPTY")) ?? [["EMPTY","EMPTY","EMPTY","EMPTY","EMPTY","EMPTY"]],
			Player1Score: template.Player1Score.replaceAll(/[^\d]/g, ""),
			Player2Score: template.Player2Score.replaceAll(/[^\d]/g, ""),
		}

		return toRet;
	}, [template]);
	const {season} = useParams();
	const isLoading=false;
	const iconSize = "70%";
	// const [compact, setCompact] = useState(true);
	const [forcedPickKey, setForcedPickKey] = useState(localStorage.getItem(LSTORAGE_ROOT+"wheel") ?? "755-pt7");
	const [justinPassword, setJustinPassword] = useState(localStorage.getItem(LSTORAGE_ROOT+"justin.password") ?? "");
	const [game, setGame] = useState<number>(
		parseInt(
			init ? init.Game
			: localStorage.getItem(LSTORAGE_ROOT+"game") ?? "1"
		)
	);
	const [armor, setArmor] = useState<IArmorType>(
		init ? (init.Armour as IArmorType) : "Light"
	);
	const [stage, setStage] = useState<IStages>(
		init ? (init.Stage as IStages) : "Qualifiers"
	);
	const [forcedPickOptions, setForcedPickOptions] = useState<string[]>(["EMPTY"]);
	const [banNumber, _setBanNumber] = useState(parseInt(localStorage.getItem(LSTORAGE_ROOT+"banNumber") ?? "8"));
	const setBanNumber = (banNum) => {
		localStorage.setItem(LSTORAGE_ROOT+"banNumber", banNum.toString());
		_setBanNumber(banNum)
	}
	const [forcedPick, setForcedPick] = useState(
		init ? [init.ForcePick] : ["EMPTY"]
	);
	const [player1, setPlayer1] = useState(
		init?.Player1 ??
		localStorage.getItem(LSTORAGE_ROOT+"player1") ?? "Player 1"
		);
		const [player2, setPlayer2] = useState(
		init?.Player2 ??
		localStorage.getItem(LSTORAGE_ROOT+"player2") ?? "Player 2"
	);
	const [player1Score, setPlayer1Score] = useState(init ? parseInt(init.Player1Score) : 0);
	const [player2Score, setPlayer2Score] = useState(init ? parseInt(init.Player2Score) : 0);
	const playerScores = [player1Score, player2Score];
	const setPlayerScores = [setPlayer1Score, setPlayer2Score];
	const [player1Ban, setPlayer1Ban] = useState(init ? init.Player1Bans : []);
	const [player2Ban, setPlayer2Ban] = useState(init ? init.Player2Bans : []);
	const [playerBans, setPlayerBans] = [[player1Ban, player2Ban], [setPlayer1Ban, setPlayer2Ban]];
	const armorOptions:IArmorType[] = ["Light", "Heavy", "Special", "Elastic"];
	const stageOptions:IStages[] = ["Qualifiers", "Groups", "Finals"];
	const isQualifiers = stage === "Qualifiers";
	const [player1Teams, setPlayer1Teams] = useState<string[][]>(init ? init.Player1Teams : []);
	const [player2Teams, setPlayer2Teams] = useState<string[][]>(init ? init.Player2Teams : []);
	const [isSubmitted, setIsSubmitted] = useState(init ? true : false);
	useEffect(() => {
		if (!!!init) {
			setIsSubmitted(false);
		}
	}, [player1, player2, game, armor, stage, banNumber])
	const justinData:IJustinTournamentData = useMemo<IJustinTournamentData>(() => {
		const jStage = stage.toLocaleLowerCase() //.replace("finals", "elimination")
		const jP1Ban = [], jP2Ban = [];
		for (const ban of player1Ban) {
			jP1Ban.push(studentMap.getId(ban) ?? ban);
		}
		if (!isQualifiers) {
			for (const ban of player2Ban) {
				jP2Ban.push(studentMap.getId(ban) ?? ban);
			}
		}
		return {
			Tournament: `tournament-${season}`,
			Stage: jStage,
			Game: `game-${game}`,
			Player1: player1,
			Player2: isQualifiers ? "n/a" : player2,
			Armour: armor.toLocaleLowerCase(),
			ForcePick: studentMap.getId(forcedPick[0]),
			Player1Bans: jP1Ban,
			Player2Bans: jP2Ban,
			Player1Teams: player1Teams,
			Player2Teams: isQualifiers ? [null, null, null, null, null, null] : player2Teams,
			Player1Score: player1Score.toString(), // confirm if string or number
			Player2Score: isQualifiers ? "" : player2Score.toString(),
		}
	}, [season, stage, game, player1, player2, isQualifiers, armor, forcedPick, player1Ban, player2Ban, player1Teams, player2Teams, player1Score, player2Score]);
	const [info, setInfo] = useState<IRaidInfo>({
		season: -1,
		playable: { unknown: 0 },
		start: "0",
		end: "0",
		seasonType: -1
	});
	const toast= useToast();
	
	const qualifierBans = useMemo(()=>info?.ban?.qualifiers ?? DEFAULT_QUALIFIER_BANS, [info]);
	const overallBans = useMemo(() => {
		if (isQualifiers) return qualifierBans;

		const toret = [...new Set([...player1Ban, ...player2Ban])];
		while(toret.length < banNumber) {
			toret.push("EMPTY");
		}

		return toret;
	}, [qualifierBans, isQualifiers, player1Ban, player2Ban, banNumber]);

	const [player1TimeStr, player2TimeStr, timeDifference1, timeDifference2] = useMemo(()=>{
		const calc = SCORE_CALCULATORS.getCalculators()[5];
		if (!calc) return ["", "", "", ""];
		const raw = calc.calculateSecondsElapsed(player1Score);
		const raw2 = calc.calculateSecondsElapsed(player2Score);
		return [
			secondsToTime(raw),
			secondsToTime(raw2),
			secondsToTime(raw-raw2),
			secondsToTime(raw2-raw),
		]
	}, [player1Score, player2Score, SCORE_CALCULATORS]);
	
	const keys = info?.playable ? Object.keys(info?.playable) : [];
	const raidName = keys[0]?.split("_")[0] ?? "Unknown Raid";
	useEffect(() => {
		let ignore = false;
		async function loadInfo() {
			const result = await getJson(`https://ba-api.midokuni.com/wheelofnames/${forcedPickKey}`);
			if (!ignore) {
				setForcedPickOptions(result.data.wheelConfig.entries.map(e => e.text.trim()).sort());
			}
		}

		loadInfo();

		return () => {
			ignore = true
		}
	}, [forcedPickKey])
	
	useEffect(() => {
		let ignore = false;
		const root = BUCKET_HOST+`/files/data/grandRaid/tournament/${season.toString()}`;
		async function loadInfo() {
			const result = await getJson(`${root}/info.json`);
			if (!ignore) {
				setInfo(result);
			}
		}

		loadInfo();

		return () => {
			ignore = true
		}
	}, [season])
	useEffect(() => {
		let ignore = false;
		if (!ignore) {
			SCORE_CALCULATORS.setCalculators(RaidScore.fromRaid("", 50, raidName));
		}
		return () => {
			ignore = true
		}
	}, [raidName]);
	
	const handleFocus = (event:React.ChangeEvent<HTMLInputElement>) => {
		event.target.select();
	}

	async function submitJustin() {
		const url = "https://api.justin163.com/settourneydata";
		const rawResponse = await fetch(url, {
			method: 'POST',
			headers: new Headers({
				'Content-Type': 'application/json',
				'TournamentPassword': justinPassword
			}),
			body: JSON.stringify(justinData),
		});
		const data = await rawResponse.json();
		switch (rawResponse.status) {
			case 200:
				setIsSubmitted(true);
				toast.addToast("Successfully submitted data to Justin163");
				break;
			default:
				toast.addToast(`Error happened while submitting data to Justin163:\n\n${data.Error}`);
				alert(`Error happened while submitting data to Justin163:\n\n${data.Error}`);
				break;
		}
		// getJustin(); //Test

		return data;
	}
	const configTitle = `${stage} Configuration - ${isQualifiers ? player1 : `${player1} vs ${player2}`}`

	async function downloadImage() {
		const su = document.getElementById("setting-summary");
		
		try {
			const dataUrl = await domToImage.toPng(su);
			const link = document.createElement('a');
			link.href = dataUrl;
			link.download = configTitle+'.png';
			link.click();
		  } catch (error) {
			console.error('Error capturing or copying image:', error);
		  }
	}
	async function copyImage() {
		const su = document.getElementById("setting-summary");
		
		try {
			const dataUrl = await domToImage.toPng(su);
			const canvas:any = document.createElement('canvas');
			// console.log(su.parentElement.style.background)
			// canvas.bgcolor = "red"
			// canvas.bgcolor = window.getComputedStyle(su).getPropertyValue('--bs-body-bg')
			canvas.width = su.offsetWidth;
			canvas.height = su.offsetHeight;
			const ctx = canvas.getContext('2d');
			const img = new Image();
			img.src = dataUrl;
			img.onload = () => {
			  ctx?.drawImage(img, 0, 0);
			  canvas.toBlob(function(blob) { 
				const item = new ClipboardItem({ "image/png": blob });
				navigator.clipboard.write([item]); 
				toast.addToast("Copied to clipboard");
			});
			};
		  } catch (error) {
			console.error('Error capturing or copying image:', error);
		  }
	}
	return (
	<>
		<Row visible={isLoading}>
			<Col></Col>
			<Col style={styles.spinnerRow}>
				<MyLoadingSpinner isLoading={isLoading}/>
			</Col>
			<Col></Col>
		</Row>
		
		{ init ? null : <Row>
			<Col>
				<RaidTerrainImage name={raidName} terrain={keys[0]?.split("_")[1] ?? ""} >
					<Row>
						<Col>
							<Row id="setting-summary" className="screenshot" style={{backgroundColor: "var(--bs-body-bg)"}}>
								<Col style={styles.container}>
									<Row>
										<Col><h2 className="text-pink">{configTitle}</h2></Col>
									</Row>
									<Row>
										<Col>Game {game}</Col>
									</Row>
									<Row>
										{/* <Col xs={1}></Col> */}
										<Col xs={2}>
											<Row>
												<Col><h4 className="text-pink">Armor</h4></Col>
											</Row>
											<Row>
												<Col style={styles.icon}>
													<Row><Col><Images.Armor color={armor} size={iconSize} /></Col></Row>
													<Row><Col><h5><strong>{armor}</strong></h5></Col></Row>
													{/* <Row><Col>{armor}</Col></Row> */}
													{/* <Row><Col><TagBadge>{armor}</TagBadge></Col></Row> */}
												</Col>
											</Row>
										</Col>
										<Col xs={2}>
											<Row>
												<Col><h4 className="text-pink">Forced Pick</h4></Col>
											</Row>
											<Row>
												<Col><StudentRenderer bare model={Student.fromName(studentMap.getProperName(forcedPick[0]))} /></Col>
											</Row>
										</Col>
										<Col>
											<Row>
												<Col><h4 className="text-pink">Bans</h4></Col>
											</Row>
											<Row>
												{overallBans.slice(0, Math.floor(overallBans.length/2)).map((n, i) => {
													return <Col key={`ban_preview_${i}`}><StudentRenderer compact={2} bare model={Student.fromName(studentMap.getProperName(n))} /></Col>
													})}
											</Row>
											<Row>
												{overallBans.slice(Math.floor(overallBans.length/2)).map((n, i) => {
													return <Col key={`ban_preview_${i}`}><StudentRenderer compact={2} bare model={Student.fromName(studentMap.getProperName(n))} /></Col>
													})}
											</Row>
										</Col>
										{/* <Col xs={1}></Col> */}
									</Row>
									<Row style={styles.brand}>
										<Col>
											<MyBrand />
										</Col>
									</Row>
								</Col>
							</Row>
						</Col>
					</Row>
					<Row>
						<Col>
							<Button variant="violet" onClick={downloadImage} >Download</Button>
							<Button variant="violet" onClick={copyImage} >Copy</Button>
						</Col>
					</Row>
				</RaidTerrainImage>
			</Col>
		</Row>
		}
		<Row hidden={isSubmitted}>
			<Col>
				<Alert variant="warning">Data has not yet been submitted.</Alert>
			</Col>
		</Row>
		<Row>
			<Col style={styles.container} xs={12} md={7} xl={3}>
				<Row><Col><h2 className="text-pink">Settings</h2></Col></Row>
				<Row>
					<Col xs={2}><label>Game</label></Col>
					<Col>
						<Form.Select value={game}
									disabled={init ? true : false} onChange={(event) => {
							if (event.target.value.length) {
								localStorage.setItem(LSTORAGE_ROOT+"game", event.target.value);
								setGame(parseInt(event.target.value))
							} else {
								localStorage.setItem(LSTORAGE_ROOT+"game", "1");
								setGame(1)
							}
						}}>
							{Array.from({length: 10}, (_, i) => i + 1).map(opt => {
								return (<option key={opt} value={opt}>{opt}</option>);
							})}
						</Form.Select>
					</Col>
				</Row>
				<Row>
					<Col xs={2}><label>Armor</label></Col>
					<Col>
						<Row>
							{armorOptions.map(a => {
								return <Col><Form.Check // prettier-ignore
									checked={armor === a}
									onSelect={()=>setArmor(a)}
									onChange={()=>setArmor(a)}
									disabled={init ? true : false}
									key={`armor_${a}`}
									type="radio"
									id={`armor_${a}`}
									label={a}
									// label={<TagBadge>{a}</TagBadge>}
								/></Col>
							})}	
						</Row>
					</Col>
				</Row>
				<Row>
					<Col xs={2}><label>Stage</label></Col>
					<Col>
						<Row>
							{stageOptions.map(a => {
								return <Col><Form.Check // prettier-ignore
									checked={stage === a}
									onSelect={()=>setStage(a)}
									onChange={()=>setStage(a)}
									disabled={init ? true : false}
									key={`stage_${a}`}
									type="radio"
									id={`stage_${a}`}
									label={a}
								/></Col>
							})}	
						</Row>
					</Col>
				</Row>
				<Row>
					<Col xs={2}><label>Player 1</label></Col>
					<Col>
						<input
									disabled={init ? true : false} type="text" value={player1} onFocus={handleFocus} onChange={(e) => {
							localStorage.setItem(LSTORAGE_ROOT+"player1", e.target.value);
							setPlayer1(e.target.value)
						}}></input>
					</Col>
				</Row>
				<Row>
					<Col><Button disabled={init ? true : isQualifiers} onClick={(e)=>{
					const temp = player2;
						localStorage.setItem(LSTORAGE_ROOT+"player1", temp);
						localStorage.setItem(LSTORAGE_ROOT+"player2", player1);
						setPlayer2(player1);
						setPlayer1(temp);
					}} variant="violet">&uarr;&uarr; Swap &darr;&darr;</Button></Col>
				</Row>
				<Row>
					<Col xs={2}><label>Player 2</label></Col>
					<Col>
						<input type="text" disabled={init ? true : isQualifiers} value={isQualifiers ? "Qualfiiers has no Player 2" : player2} onFocus={handleFocus} onChange={(e) => {
							localStorage.setItem(LSTORAGE_ROOT+"player2", e.target.value);
							setPlayer2(e.target.value)
						}}></input>
					</Col>
				</Row>
				<Row>
					<Col xs={2}><label>Total Bans</label></Col>
					<Col>
						<Form.Select value={banNumber} onChange={(event) => {
							if (event.target.value.length) {
								setBanNumber(parseInt(event.target.value))
							} else {
								setBanNumber(2)
							}
						}}>
							{Array.from({length: 10}, (_, i) => i + 1).map(opt => {
								return (<option key={opt*2} value={opt*2}>{opt*2}</option>);
							})}
						</Form.Select>
					</Col>
				</Row>
				<Row>
					<Col xs={2}><label>Wheel Key</label></Col>
					<Col>
						<input type="text" value={forcedPickKey} onFocus={handleFocus} onChange={(e) => {
							localStorage.setItem(LSTORAGE_ROOT+"wheel", e.target.value);
							setForcedPickKey(e.target.value)
						}}></input>
					</Col>
				</Row>
				<Row>
					<Col xs={2}><label>Justin Password</label></Col>
					<Col>
						<input type="password" disabled={init ? true : false} value={justinPassword} onFocus={handleFocus} onChange={(e) => {
							localStorage.setItem(LSTORAGE_ROOT+"justin.password", e.target.value);
							setJustinPassword(e.target.value)
						}}></input>
					</Col>
				</Row>
				<Row><Col xs={12}><Button variant="violet" onClick={(e)=>{
					submitJustin();
				}}>Submit to Justin</Button></Col></Row>
				<Row><Col><small>This tool does not validate data before sending to Justin to respect actual mishaps during the tournament. Please read the warnings in the website to ensure no mishaps are apparent and to double check the correctness of the data.</small></Col></Row>
			</Col>
			<Col style={{...styles.container}} xs={12} md={4} xl={2}>
				<Row><Col><h2 className="text-pink">Forced Pick</h2></Col></Row>
				<Row>
					<Col xs={4}><Button variant="violet" onClick={() => {
						setForcedPick([forcedPickOptions[Math.floor(Math.random() * forcedPickOptions.length)]]);
					}}>&#x21bb; Reroll</Button></Col>
					<Col xs={1}>or</Col>
					<Col>
						<Form.Select value={forcedPick} onChange={(event) => {
							if (event.target.value.length) {
								setForcedPick([event.target.value])
							} else {
								setForcedPick(["EMPTY"])
							}
						}}>
							<option key={"EMPTY"} value={"EMPTY"}>{"EMPTY"}</option>
							{forcedPickOptions.map(opt => {
								return (<option key={opt} value={opt}>{opt}</option>);
							})}
						</Form.Select>
					</Col>
				</Row>
				<Row visible={forcedPickOptions.length > 0}>
					<Col>
						<StudentRenderer bare model={Student.fromName(studentMap.getProperName(forcedPick[0]))} />
					</Col>
				</Row>
				<Row style={(forcedPickOptions.length === 0) ? {} : {marginTop: "-80%", paddingBottom: "80%"}} visible={forcedPick[0] === "EMPTY" || forcedPickOptions.length === 0}>
					<Col style={styles.spinnerRow}>
						<MyLoadingSpinner text="" size={"100%"} isLoading={forcedPick[0] === "EMPTY" || forcedPickOptions.length === 0}/>
					</Col>
				</Row>
			</Col>
			<Col>
				<Row><Col><h2 className="text-pink">{stage} Bans</h2></Col></Row>
				
				{ isQualifiers && <Row><Col style={styles.container}><Row>{qualifierBans.map((n, i) => {
					return <Col xs={4} md={2} key={`ban_preview_${i}`}><StudentRenderer compact={2} bare model={Student.fromName(studentMap.getProperName(n))} /></Col>
				})}</Row></Col></Row>}

				{ !isQualifiers && <BanRegion forcedPick={forcedPick[0]} banNumber={banNumber} player1={player1} player2={player2} playerBans={playerBans} setPlayerBans={setPlayerBans} />}
			</Col>
		</Row>
		<Row>
			<Col xs={12} xl={12}>
				<Row>
					<Col><h2 className="text-pink">{player1} Results/Teams</h2></Col>
				</Row>

				<Row>
					<Col xs={2}><label>Score</label></Col>
					<Col>
						<input type="number" step={100000} value={playerScores[0]} onFocus={handleFocus} onChange={(e) => {
							setPlayerScores[0](parseInt(e.target.value));
						}}></input> ({playerScores[0].toLocaleString()})
					</Col>
				</Row>
				<Row>
					<Col xs={2}><label>Time</label></Col>
					<Col>{player1TimeStr}</Col>
				</Row>
				<Row hidden={isQualifiers}>
					<Col xs={2}><label>Gap</label></Col>
					<Col>
						{(playerScores[0] - playerScores[1]).toLocaleString()} ({timeDifference1})
					</Col>
				</Row>
				<Row>
					<Col onClick={(e)=>{
					}}>
						<PlayerTeamRegion initTeams={init ? player1Teams : undefined} setTeams={setPlayer1Teams} teamsPosition="L" player={player1} forcedPick={forcedPick[0]} bans={overallBans}  />
					</Col>
				</Row>
			</Col>
			{!isQualifiers && <Col>
				<Row>
					<hr></hr>
					<hr></hr>
					<Col><h2 className="text-pink">{player2} Results/Teams</h2></Col>
				</Row>
				<Row>
					<Col xs={2}><label>Score</label></Col>
					<Col>
						<input type="number" value={playerScores[1]} onFocus={handleFocus} onChange={(e) => {
							setPlayerScores[1](parseInt(e.target.value));
						}}></input> ({playerScores[1].toLocaleString()})
					</Col>
				</Row>
				<Row>
					<Col xs={2}><label>Time</label></Col>
					<Col>{player2TimeStr}</Col>
				</Row>
				<Row>
					<Col xs={2}><label>Gap</label></Col>
					<Col>
						{(playerScores[1] - playerScores[0]).toLocaleString()} ({timeDifference2})
					</Col>
				</Row>
				<Row>
					<Col onClick={(e)=>{
					}}>
						<PlayerTeamRegion initTeams={init ? player2Teams : undefined} setTeams={setPlayer2Teams} teamsPosition="L" player={player2} forcedPick={forcedPick[0]} bans={overallBans} />
					</Col>
				</Row>
			</Col>}
		</Row>
	</>
	);
}
interface IPlayerTeamProps {
	player: string;
	active?: boolean;
	forcedPick: string;
	bans: string[];
	initTeams?: string[][];
	teamsPosition: "L" | "R",
	setTeams: (teams:string[][]) => void;
}

function CreateTeam() {
	return ["EMPTY","EMPTY","EMPTY","EMPTY","EMPTY","EMPTY"];
}

interface ITeamRendererProps {
	team: Student[]; 
	handleClick: (e:number)=>void; 
	handleDrag: (e, studenti:number)=>void;
	handleDrop: (e, studenti:number)=>void;
	highlight?: number;
	compact?: boolean;
}

function GrandTourneyTeamRenderer(props: ITeamRendererProps) {
	const {team, compact, handleClick} = props;
	if (!!!team) return null;
	return (
		<Row className="" style={{margin:2, padding:2}}>
			{/* <Col lg={2} className="text-center">
				<Sticky offset={70}>
					<h3 className="text-pink">{label ?? "Unit"}</h3>
					<h5 className="text-pink">{sublabel}</h5>
				</Sticky>
			</Col> */}
			<Col>
				<Row>
					{Object.keys(team).map(i=>parseInt(i)).map(i => <Col xs={2} key={i} onClick={(e)=>handleClick(i)}
						draggable
						onDragOver={(e) => e.preventDefault()}
						onDragEnter={(e) => e.preventDefault()}
						onDragExit={(e) => e.preventDefault()}
						onDragStart={(e) => {
							// e.preventDefault();
							// props.handleDrag(e, i)
							// let image: JSX.Element = (<></>); // <== whatever you want here

							// var ghost = document.createElement('div');
							// ghost.style.transform = "translate(-200vw, -200vh)";
							// ghost.style.position = "absolute";
							// document.body.appendChild(ghost);
							
							// e.dataTransfer.dropEffect = "move"
							// e.dataTransfer.clearData();
							// e.dataTransfer.setData("text/plain", team[i].getName())
							// e.dataTransfer.setDragImage(ghost, 0, 0);
						}}
						// onDrag={(e) => e.preventDefault()}
						onDrag={(e) => props.handleDrag(e, i)}
						onDrop={(e) => props.handleDrop(e, i)}
						style={props.highlight === i ? styles.highlight : undefined} ><StudentRenderer bare compact={compact} model={team[i]} /></Col>
					)}
				</Row>
			</Col>
		</Row>
	)
}


const MAX_STRIKERS = 4;
const MAX_SPECIALS = 2;
const MAX_STUDENTS = MAX_SPECIALS + MAX_STRIKERS;
function PlayerTeamRegion(props: IPlayerTeamProps) {
	const {
		player,
		forcedPick,
		setTeams,
		initTeams,
	} = props;
	const id = player.replaceAll(/\s/g, "-");
	const bans = props.bans.filter(s=>s !== "EMPTY");
	const [isSwap, setIsSwap] = useState(false);
	const [searchString, setSearchString] = useState("");
	const isSearchVisible = true;
	// const isSearchVisible = props.active;
	// const [isSearchVisible, setIsSearchVisible] = useState(true);
	const [students, _setStudents] = useState(initTeams ? initTeams.reduce((accumulator, value) => accumulator.concat(value), []) : CreateTeam());
	const setStudents = (s: string[]) => {
		_setStudents(s);
		const teams = [];
		let team = [];
		for(const member of s) {
			if (member === "EMPTY") {
				team.push(null);
			} else {
				team.push(studentMap.getId(member) ?? member);
			}
			if (team.length === 6) {
				teams.push(team);
				team = [];
			}
		}
		setTeams(teams);
	}
	const [dragging, setDragging] = useState<number>(null);
	const teams = useMemo(() => {
		const toRet:Student[][] = [];
		for(let i=0; i<students.length;) {
			const team = [
				Student.fromName(studentMap.getProperName(students[i++])),
				Student.fromName(studentMap.getProperName(students[i++])),
				Student.fromName(studentMap.getProperName(students[i++])),
				Student.fromName(studentMap.getProperName(students[i++])),
				Student.fromName(studentMap.getProperName(students[i++])),
				Student.fromName(studentMap.getProperName(students[i++])),
			];
			toRet.push(team);
		}
		return toRet;
	}, [students]);
	const isForcedPickOk = useMemo(() => {
		return students.indexOf(forcedPick) >= 0;
	}, [forcedPick, students]);
	const isBanOk = useMemo(() => {
		for(const ban of bans) {
			if (students.indexOf(ban) >=0) return false;
		}
		return true;
	}, [bans, students]);
	const isDupeOk = useMemo(() => {
		const filtered = students.filter(s=>s !== "EMPTY");
		return filtered.length === new Set(filtered).size;
	}, [students]);
	const [selectedIndex, setSelectedIndex] = useState<number>(0);
	// const setSelectedBanIndex = (a:number) => setSelectedIndex(a%banNumber);

	const [matches, unmatches] = useMemo(()=>{
		const data1 = [...new Set(studentMap.getStudentNames().filter((n) => n.toLowerCase().indexOf(searchString.toLowerCase()) >= 0 && n.length > 0).map(n => studentMap.getProperName(n)))];
		const data2 = [...new Set(studentMap.getStudentNames().filter((n) => n.toLowerCase().indexOf(searchString.toLowerCase()) < 0 && n.length > 0).map(n => studentMap.getProperName(n)))];
		
		if (searchString.trim().length === 0) {
			if (data1.length < 9) {
				// const temp = data2.splice(9-data1.length);
				data1.push(...data2);
				return [[...new Set(data1)].filter((_,i)=>i <= MAX_RESULTS), data2];

			}

			return [data1.sort(), data2.sort()];
		}
		const toRet = [sortByLevenshteinDistance(data1, searchString), sortByLevenshteinDistance(data2, searchString)];
		if (toRet[0].length < 9) {
			// const temp = toRet[1].splice(9-data1.length);
			toRet[0].push(...toRet[1]);
			return [[...new Set(toRet[0])].filter((_,i)=>i <= MAX_RESULTS), toRet[1]];
		}

		return [toRet[0].filter((_,i)=>i <= MAX_RESULTS), toRet[1]];
	}, [searchString]);
	function addStudent(student: string) {
		if (students.indexOf(student) >= 0) {
			// STUDENT ALREADY IN TEAM
		}
		
		let test = [...students];
		if (selectedIndex+1 >= students.length) {
			test = [...students, ...CreateTeam()];
		} 
		test[selectedIndex] = student;
		setStudents(test);
		setSelectedIndex( selectedIndex+1 );
		focusTextBox(`${id}-team-search`);
	}
	const xs = 8;

		return (<>
		<Row hidden={isBanOk && isDupeOk && isForcedPickOk}>
			<Col>
				<Alert hidden={isBanOk} variant="danger">Has a Banned Student!</Alert>
				<Alert hidden={isDupeOk} variant="danger">Has a Duplicate Student!</Alert>
				<Alert hidden={isForcedPickOk} variant="danger">Forced Pick is Missing!</Alert>
				
			</Col>
		</Row>
		<Row>
			<Col xs={xs-1} style={styles.container}>
				<Row>
					<Col>
						<Button variant="violet" onClick={()=>setIsSwap(!isSwap)}>Swap Student Position</Button>
					</Col>
				</Row>
				{teams.map((team, i) => {
					return <Row key={`team_${player}_${i}`}>
						<Col><GrandTourneyTeamRenderer compact highlight={Math.floor(selectedIndex/MAX_STUDENTS) === i ? selectedIndex % MAX_STUDENTS : undefined} 
						handleDrag={(e, studenti) => {
							e.preventDefault();
							if (!!dragging) return;
							setDragging(i*MAX_STUDENTS + studenti)
							setSelectedIndex(i*MAX_STUDENTS + studenti)
							// console.log(document.getElementById("ghost"));
							// e.dataTransfer.dropEffect = "move"
							// e.dataTransfer.setData("text/plain", studenti)
							// e.dataTransfer.setDragImage(document.getElementById("ghost"),0,0)
							// console.log(e);
							// console.log(e.dataTransfer.getData("text/plain"));
							// e.dataTransfer.setDragImage(new Image(1,1), 0, 0);
							// e.dataTransfer.setDragImage(<StudentRenderer bare model={Student.fromName(studentMap.getProperName(students[dragging]))} />, 0, 0);
							// setCursor({
							// 	x: e.pageX,
							// 	y: e.pageY,
							// });
							// console.log("DRAG");
							// console.log(studenti);
							// console.log(e);
						}}
						handleDrop={(e, studenti) => {
							e.preventDefault();
							// e.stopPropagation();
							setDragging(null)
							
							const prevSelected = students[selectedIndex];
							const currSelected = students[i*MAX_STUDENTS + studenti];
							
							let tempStudents = [...students];
							tempStudents[selectedIndex] = currSelected;
							tempStudents[i*MAX_STUDENTS + studenti] = prevSelected;
							setStudents(tempStudents);
							// console.log("DROP");
							// console.log(studenti);
							// console.log(e);
						}}
						handleClick={(studenti)=>{
							if (isSwap) {
								const prevSelected = students[dragging];
								const currSelected = students[i*MAX_STUDENTS + studenti];
								
								let tempStudents = [...students];
								tempStudents[selectedIndex] = currSelected;
								tempStudents[i*MAX_STUDENTS + studenti] = prevSelected;
								setStudents(tempStudents);
								focusTextBox(`${id}-team-search`);
								setIsSwap(false);
							} 
							setSelectedIndex(i*MAX_STUDENTS + studenti)
							focusTextBox(`${id}-team-search`);
						}} 
						team={team} /></Col>
						<Col xs={1}>
							<Row hidden={i===0}><Col><Button variant="violet" onClick={(e)=>{
								const temp = [...students];
								const teamRemoved = temp.splice(MAX_STUDENTS * i, MAX_STUDENTS);
								temp.splice(MAX_STUDENTS * (i-1), 0, ...teamRemoved)
								setStudents(temp);
							}}>&uarr;&uarr; &darr;&darr;</Button></Col></Row>
							<Row><Col><Button variant="danger" style={{marginTop: "10%"}} onClick={(e)=>{
								const temp = [...students];
								temp.splice(MAX_STUDENTS * i, MAX_STUDENTS);
								setStudents(temp);
							}}><CloseButton aria-label="Add-Team"/>DEL</Button></Col></Row>
						</Col>
					</Row>
					})}
				<Row><Col><Alert hidden={!isSwap} variant="primary">Select the student to swap positions with.</Alert></Col></Row>
				<Row style={{paddingLeft: "10%", paddingRight: "10%"}}>
					{/* <Col> */}
						<Button variant="violet" onClick={(e)=>{
							setStudents([...students, ...CreateTeam()]);
							focusTextBox(`${id}-team-search`);
						}}><CloseButton style={{marginLeft:5, transform: "rotate(45deg)"}} aria-label="Add-Team"/> Add Team</Button>
					{/* </Col> */}
				</Row>

			</Col>
			<Col style={{...styles.container, ...(!isSearchVisible ? styles.dim:undefined)}} 
		// hidden={!isSearchVisible}
		>
			<Row>
				<Col>
					<Row>
						<Col>
						Automatically adds a new team if needed
						</Col>
					</Row>
					<Row>
						<Col>
							<StudentPicker id={`${id}-team-search`} ignoreNumericSpecials setTarget={(target) => setSearchString(target ?? "")} target={searchString} handleKeyDown={(e) => {
								const {key, shiftKey, altKey, ctrlKey} = e;
								if (key === "Tab" || key === "Enter") {
									addStudent(matches[0]);
									focusTextBox(`${id}-team-search`);
									return;
								} 
								let i = parseInt(key);
								if (Number.isNaN(i)) return;
								if (key === "0") return;
								i--;
								if(matches.length <= i) return;
								addStudent(matches[i]);
								focusTextBox(`${id}-team-search`);
							}} />
						</Col>
					</Row>
					<Row style={{overflowY: "scroll", maxHeight: "30vh"}}>
						{matches.slice(0, Math.min(matches.length, 9)).map((id, i)=><Col key={id} xs={6} md={4} onClick={(e)=>{
							addStudent(id)
						}}>
							<Row><Col><StudentRenderer compact bare model={NumberedStudent.fromNameWithNumber(studentMap.getProperName(id), i+1)} /></Col></Row>
						</Col>
						)}
						<hr></hr>
						<hr></hr>
						{matches.slice(Math.min(matches.length, 9)).map(id=><Col key={id} xs={6} md={4}  onClick={(e)=>{
							addStudent(id)
						}}>
							<StudentRenderer compact bare model={Student.fromName(studentMap.getProperName(id))} />
						</Col>
						)}
						<hr></hr>
						<hr></hr>
						{UNMATCH_VISIBLE && unmatches.map(id=><Col key={id} xs={6} md={4} onClick={(e)=>{
							addStudent(id)
						}}>
							<StudentRenderer compact bare model={Student.fromName(studentMap.getProperName(id))} />
						</Col>
						)}
					</Row>
				</Col>
			</Row>
		</Col>
		</Row>
	</>);
}

function BanRegion(props: IBanProps) {
	const {
		banNumber, 
		player1, 
		player2,
		playerBans,
		setPlayerBans,
		forcedPick
	} = props;
	const [searchString, setBanSearchString] = useState("");
	const isBanSearchVisible = true;
	// const [isBanSearchVisible, setIsBanSearchVisible] = useState(true);
	const [_selectedBanIndex, _setSelectedBanIndex] = useState(0);
	const selectedBanIndex = _selectedBanIndex % banNumber;
	const setSelectedBanIndex = (a:number) => _setSelectedBanIndex(a%banNumber);
	const isForcedPickOk = useMemo(() => {
		return playerBans[0].indexOf(forcedPick) < 0 && playerBans[1].indexOf(forcedPick) < 0;
	}, [playerBans, forcedPick]);
	const [player1BanDisplay, player2BanDisplay, isBanOk] = useMemo(() => {
		const toret1 = [...new Set([...playerBans[0]])];
		while(toret1.length < banNumber/2) {
			toret1.push("EMPTY");
		}

		const toret2 = [...new Set([...playerBans[1]])];
		while(toret2.length < banNumber/2) {
			toret2.push("EMPTY");
		}
		
		const toret3 = [...new Set([...playerBans[0], ...playerBans[1]])];

		return [toret1, toret2, toret3.length === banNumber];
	}, [playerBans, banNumber]);
	// const player2BanDisplay = useMemo(() => {
	// 	const toret = [...new Set([...playerBans[1]])];
	// 	while(toret.length < banNumber/2) {
	// 		toret.push("EMPTY");
	// 	}

	// 	return toret;
	// }, [playerBans, banNumber]);
	
		const [matches, unmatches] = useMemo(()=>{
			const data1 = [...new Set(studentMap.getStudentNames().filter((n) => n.toLowerCase().indexOf(searchString.toLowerCase()) >= 0 && n.length > 0).map(n => studentMap.getProperName(n)))];
			const data2 = [...new Set(studentMap.getStudentNames().filter((n) => n.toLowerCase().indexOf(searchString.toLowerCase()) < 0 && n.length > 0).map(n => studentMap.getProperName(n)))];
			if (searchString.trim().length === 0) {
				if (data1.length < 9) {
					data1.push(...data2);
					return [[...new Set(data1)].filter((_,i)=>i <= MAX_RESULTS), data2];

				}

				return [data1.sort(), data2.sort()];
			}
			const toRet = [sortByLevenshteinDistance(data1, searchString), sortByLevenshteinDistance(data2, searchString)];
			if (toRet[0].length < 9) {
				toRet[0].push(...toRet[1]);
				return [[...new Set(toRet[0])].filter((_,i)=>i <= MAX_RESULTS), toRet[1]];
			}

			return [toRet[0].filter((_,i)=>i <= MAX_RESULTS), toRet[1]];
		}, [searchString]);


		function addBanToPlayer(student: string, player: number) {
			if (selectedBanIndex % 2 === player) return addBan(student);

			let test = [...new Set([...playerBans[player], student])];
			setPlayerBans[player](test.slice(Math.max(0, test.length-4), test.length));
			focusTextBox("ban-search");
		}

		function findNextBanIndex() {
			// if (playerBans[0].length === playerBans[1].length) {
				return (selectedBanIndex+1)%banNumber;
			// } else if (playerBans[0].length > playerBans[1].length) {
			// 	return playerBans[1].length*2;
			// } else {
			// 	return playerBans[0].length*2-1;
			// }
		}

		function addBan(student: string) {
			const player = selectedBanIndex % 2;
			const index = (selectedBanIndex - player) / 2;
			// if (playerBans[player].length < banNumber / 2) {
			// 	setPlayerBans[player]([...new Set([...playerBans[player], student])].filter(a=>a!=="EMPTY"));
			// } else {
				const test = [...new Set([...playerBans[player]])];
				test[index] = student;
				setPlayerBans[player]([...new Set([...test].filter(a=>a!=="EMPTY"))]);
			// }
			setSelectedBanIndex( findNextBanIndex() );
			focusTextBox("ban-search");
		}
		return (<>
		<Row hidden={isBanOk && isForcedPickOk}>
			<Col>
				<Alert hidden={isBanOk} variant="danger">Ban Mismatch!</Alert>
				<Alert hidden={isForcedPickOk} variant="danger">Forced Pick student has been Banned!</Alert>
			</Col>
		</Row>
		<Row>
			<Col style={styles.container}>
				<Row><Col><h3 className="text-pink">{player1} Bans</h3></Col></Row>
				<Row>
					{player1BanDisplay.map((n, i) => {
						return <Col xs={11} md={5} key={`ban_preview_1_${i}`} style={selectedBanIndex%2 === 0 && selectedBanIndex/2 === i ? styles.highlight : undefined} onClick={(e) => {
							setSelectedBanIndex(i*2);
							focusTextBox("ban-search");
						}} >
							<StudentRenderer compact={2} bare model={Student.fromName(studentMap.getProperName(n))} />
						</Col>
						})}
				</Row>
			</Col>
			<Col style={styles.container}>
				{/* <Row><Col>SELECT STUDENT BANNED BY PLAYER</Col></Row> */}
				{/* <Row>
					<Col>
						<Button variant='outline-secondary' onClick={() => setIsBanSearchVisible(!isBanSearchVisible)}>
							<span className="navbar-toggler-icon" />
							Toggle
						</Button>
					</Col></Row> */}
				<Row><Col>
					<Button variant="violet" onClick={(e) => {
						addBanToPlayer(matches[0], 0); 
							focusTextBox("ban-search");
							}}>&lt;&lt; {player1}</Button>
					</Col><Col>
					{/* <Button onClick={(e) => addBan(matches[0])}>Selected</Button> */}
					</Col><Col>
					<Button variant="violet" onClick={(e) => {
						addBanToPlayer(matches[0], 1); 
						focusTextBox("ban-search");
						}}>{player2} &gt;&gt;</Button>
				</Col></Row>
				<Row>
				</Row>
				<Row><Col hidden={!isBanSearchVisible}>
					<Row>
						<Col>
							<Row>
								<Col>
									<StudentPicker id="ban-search" ignoreNumericSpecials setTarget={(target) => setBanSearchString(target ?? "")} target={searchString} handleKeyDown={(e) => {
										const {key, shiftKey, altKey, ctrlKey} = e;
										if (key === "Tab" || key === "Enter") {
											addBan(matches[0]);
										} 
										// 	banMatches,
										// 	k: key,
										// 	is: Number.isInteger(key.toString())
										// });

										let i = parseInt(key);
										if (Number.isNaN(i)) return;
										if (key === "0") return;
										i--;
										if(matches.length <= i) return;
										if (shiftKey) {
											addBanToPlayer(matches[i], (selectedBanIndex+1)%2)
										} else {
											addBan(matches[i]);
										}
									}} />
								</Col>
							</Row>
							<Row style={{overflowY: "scroll", maxHeight: "30vh"}}>
								{matches.slice(0, Math.min(matches.length, 9)).map((id, i)=><Col key={id} xs={6} md={4} onClick={(e)=>{
									addBan(id)
								}}>
									<Row><Col><StudentRenderer compact bare model={NumberedStudent.fromNameWithNumber(studentMap.getProperName(id), i+1)} /></Col></Row>
								</Col>
								)}
								<hr></hr>
								<hr></hr>
								{matches.slice(Math.min(matches.length, 9)).map(id=><Col key={id} xs={6} md={4}  onClick={(e)=>{
									addBan(id)
								}}>
									<StudentRenderer compact bare model={Student.fromName(studentMap.getProperName(id))} />
								</Col>
								)}
								<hr></hr>
								<hr></hr>
								{UNMATCH_VISIBLE && unmatches.map(id=><Col key={id} xs={6} md={4} onClick={(e)=>{
									addBan(id)
								}}>
									<StudentRenderer compact bare model={Student.fromName(studentMap.getProperName(id))} />
								</Col>
								)}
							</Row>
						</Col>
					</Row>
				</Col></Row>
					
			</Col>
			<Col style={styles.container}>
				<Row><Col><h3 className="text-pink">{player2} Bans</h3></Col></Row>
				<Row>
					{player2BanDisplay.map((n, i) => {
						return <Col xs={11} md={5} key={`ban_preview_2_${i}`} style={selectedBanIndex%2 === 1 && (selectedBanIndex-1)/2 === i ? styles.highlight : undefined} onClick={(e) => {
							setSelectedBanIndex((i*2)+1);
							focusTextBox("ban-search");
						}}><StudentRenderer compact={2} bare model={Student.fromName(studentMap.getProperName(n))} /></Col>
						})}
				</Row>
			</Col>
		</Row>
		</>
		)
}
