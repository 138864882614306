import { GOOGLE_SPREADSHEET_GID, GOOGLE_SPREADSHEET_ID } from "Constants/Common";
import { IAttachable } from "Interfaces/IAttachable";
import { ICellData } from "Interfaces/ICellData";
import { IStudentMap } from "Interfaces/IStudentMap";
import Sheet from "Models/Sheet";
import { ISchaleStudent, SchaleStudent } from "Models/Student";
import { getJson } from "Utils/Common";

const SCHALE = "https://schaledb.com"

export interface IStudentMapData {
	name:string;
	id:string;
}
export default class StudentMap implements 
		IAttachable {
	static map: IStudentMap = {};
	static revMap: IStudentMap = {};
	static studentNames: string[] = [];
	static schaleStudent: SchaleStudent[] = [];

	async initialize() {
		const students = new Sheet(GOOGLE_SPREADSHEET_ID, GOOGLE_SPREADSHEET_GID.STUDENTS).initialize();
		const thoughts = new Sheet(GOOGLE_SPREADSHEET_ID, GOOGLE_SPREADSHEET_GID.THOUGHTS).initialize();
		// const test = await new Sheet("1gr1s87uS89StUT9E0e0jBM-PM8zLdGN4CPjhBi1xnsU", "1814121765").initialize();
		// console.log(test);
		
		const fullDataUrl = `https://schaledb.com/data/en/students.min.json`;
		const schale:Promise<{[id:number]: ISchaleStudent}> = getJson(fullDataUrl);

		new StudentMap()
			.populateId(await students)
			.populateAlias(await thoughts);
		try {
			// Prone to mapping errors due to data schema changes on Schale
			StudentMap.populateSchale(Object.values(await schale));
		} catch {
			console.error("Error loading Schale Student List");
			console.log(await schale);
		}
	}

	attach(data: ICellData[]) {
		const student = StudentMap.parseRow(data);
		StudentMap.map[student.name.toLocaleLowerCase()] = student.id;
		StudentMap.revMap[student.id] = student.name;
		StudentMap.studentNames.push(student.name);
	}
	static populateSchale( source: ISchaleStudent[]) {
		// const schaleStudents = source.map(s => new SchaleStudent(s)).filter(s => s.Id != 10099);
		const schaleStudents = source.map(s => new SchaleStudent(s))
			.filter((s, _, self) => {
				const copies = self.filter((s2) => s.Name === s2.Name)
				return copies.length === 1 || copies.sort((a,b)=>a.Id-b.Id)[0].Id === s.Id;
			});
		for (const student of schaleStudents) {
			if (!!!StudentMap.map[student.name.toLocaleLowerCase()]) {
				StudentMap.map[student.name.toLocaleLowerCase()] = student.id.toString();
				if (!!!StudentMap.revMap[student.id])
					StudentMap.revMap[student.id] = student.name;
				StudentMap.studentNames.push(student.name);
			}
			for (const alias of student.SearchTags) {
				if (!!!StudentMap.map[alias.toLocaleLowerCase()]) {
					StudentMap.map[alias.toLocaleLowerCase()] = student.id.toString();
					StudentMap.studentNames.push(alias);
				}
			}
		}
		StudentMap.schaleStudent = schaleStudents;
	}
	populateId(source: Sheet) {
		for (const i in source.getTable().rows) {
			const r = source.getTable().rows[i];
			this.attach(r.c);
		}

		return this;
	}

	populateAlias(source: Sheet) {
		for (const i in source.getTable().rows) {
			const r = source.getTable().rows[i];
			const name = r.c[1]?.v ?? '';
			const id = this.getId(name);
			const aliases = r.c[3]?.v?.split('\n') ?? [];
			// this.attach(r.c);
			for ( const alias of aliases) {
				StudentMap.map[alias.toLocaleLowerCase()] = id;
				StudentMap.studentNames.push(alias);
			}
		}
		return this;
	}

	getId(studentName: string) {
		try {
			return StudentMap.map[studentName.toLocaleLowerCase()] ?? undefined;
		} catch {
			return "";
		}
	}
	getIdFromUnknown(student: string) {
		return this.getId(this.getProperName(student));
	}
	getProperName(studentName:string) {
		const temp = this.getId(studentName);
		if (temp)
			return this.getName(temp);
		return this.getName(studentName);
	}

	findStudents(student: string) {
		const matches = this.getStudentNames()
			.filter(s => s.toLowerCase().startsWith(student.toLowerCase()))
			.map(s => this.getProperName(s));
		return [...new Set(matches)];
	}

	getName(studentId: string) {
		try {
			return StudentMap.revMap[studentId] ?? undefined;
		} catch {
			return undefined;
		}
	}

	getStudentNames() {
		return StudentMap.studentNames;
	}

	getSchale(studentName: string) {
		const id = this.getId(studentName);
		if (id === "") return SCHALE;
		return `${SCHALE}/student/${id}`
	}

	getIcon(studentName: string | number) {
		let id;
		if (typeof(studentName) === "number")
			id = studentName;
		else if(studentName?.match(/\d+/g)) 
			id = parseInt(studentName);
		else
			id = this.getId(studentName);
		if (!!!id || id === "") return "";
		
		return `${SCHALE}/images/student/collection/${id}.webp`
	}

	static parseRow(data:ICellData[]): IStudentMapData {
		return {
			name: data[0]?.v ?? '',
			id: data[15]?.v ?? '',
		};
	}
}